import { getCommonActions } from './commonActions'
import config from '../config'
import * as request from 'request-promise-native'

export const REQUEST_SELECT_USER_ACCOUNT_MANAGEMENT = 'REQUEST_SELECT_USER_ACCOUNT_MANAGEMENT'
export const RECEIVE_SELECT_USER_ACCOUNT_MANAGEMENT = 'RECEIVE_SELECT_USER_ACCOUNT_MANAGEMENT'
export const ERROR_SELECT_USER_ACCOUNT_MANAGEMENT = 'ERROR_SELECT_USER_ACCOUNT_MANAGEMENT'

export const userAccountManagements = () =>
  getCommonActions('USER_ACCOUNT_MANAGEMENT', {
    mapState: state => state.userAccountManagements,
    endpoint: 'user/account/management',
    searchFields: [],
    labelFields: [],
  })

const requestSelectUserAccountManagement = () => ({ type: REQUEST_SELECT_USER_ACCOUNT_MANAGEMENT })
export const receiveSelectUserAccountManagement = managementId => ({
  type: RECEIVE_SELECT_USER_ACCOUNT_MANAGEMENT,
  managementId,
})
const errorSelectUserAccountManagement = err => ({ type: ERROR_SELECT_USER_ACCOUNT_MANAGEMENT, err })

export const selectUserAccountManagement = id => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    dispatch(requestSelectUserAccountManagement())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/account/management/select`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        managementId: id,
      },
      json: true,
    }

    const { err, res } = await request(options)

    if (err) {
      dispatch(errorSelectUserAccountManagement(err))
    }

    dispatch(receiveSelectUserAccountManagement(res.managementId))
  }
}
