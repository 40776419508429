import * as request from 'request-promise-native'
import parse from 'csv-parse'
import config from '../config'
import { getCommonActions } from './commonActions'

export const RECEIVE_READINGS = 'RECEIVE_READINGS'
export const REQUEST_UPLOAD_READINGS = 'REQUEST_UPLOAD_READINGS'
export const REQUEST_DELETE_READINGS = 'REQUEST_DELETE_READINGS'
export const RECEIVE_UPLOAD_READINGS = 'RECEIVE_UPLOAD_READINGS'
export const RECEIVE_DELETE_READINGS = 'RECEIVE_DELETE_READINGS'
export const ERROR_UPLOAD_READINGS = 'ERROR_UPLOAD_READINGS'
export const READINGS_SET_TAB = 'READINGS_SET_TAB'
export const REQUEST_CHANGE_READINGS_STATUS = 'REQUEST_CHANGE_READINGS_STATUS'
export const RECEIVE_CHANGE_READINGS_STATUS = 'RECEIVE_CHANGE_READINGS_STATUS'
export const ERROR_CHANGE_READINGS_STATUS = 'ERROR_CHANGE_READINGS_STATUS'
export const TOGGLE_HI_LO_CHECK = 'TOGGLE_HI_LO_CHECK'

export const readings = () =>
  getCommonActions('READINGS', {
    mapState: state => state.readings,
    endpoint: 'reading',
    searchFields: [],
    labelFields: ['id'],
  })

const requestUploadReadings = () => {
  return {
    type: REQUEST_UPLOAD_READINGS,
  }
}

const receiveUploadReadings = readings => {
  return {
    type: RECEIVE_UPLOAD_READINGS,
    readings,
  }
}

const errorUploadReadings = err => {
  return {
    type: ERROR_UPLOAD_READINGS,
    err,
  }
}

export const setTab = tab => {
  return {
    type: READINGS_SET_TAB,
    tab,
  }
}

const requestChangeReadingsStatus = () => {
  return {
    type: REQUEST_CHANGE_READINGS_STATUS,
  }
}

const receiveChangeReadingsStatus = () => {
  return {
    type: RECEIVE_CHANGE_READINGS_STATUS,
  }
}

const errorChangeReadingsStatus = err => {
  return {
    type: ERROR_CHANGE_READINGS_STATUS,
    err,
  }
}

export const toggleHiLoCheck = open => {
  return {
    type: TOGGLE_HI_LO_CHECK,
    open,
  }
}

export const uploadReadings = (readingsCsv, loginToken, system, readTime, filename = null, buildingNumber = null) => {
  return async (dispatch, getState) => {
    dispatch(requestUploadReadings())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/reading/import2`,
      headers: {
        auth: JSON.stringify({
          token: loginToken
        }),
      },
      body: {
        readingsCsv,
        system,
        readTime,
        filename,
        buildingNumber
      },
      json: true,
    }

    const {err, res} = await request(options)

    if (err) return dispatch(errorUploadReadings(err.toString()))

    document.getElementById('upload-readings').value = null

    dispatch(readings().getNumPages({statusId: getState().readings.tab + 1}))
    dispatch(readings().refresh({statusId: getState().readings.tab + 1}))
    dispatch(receiveUploadReadings(res))
  }
}

export const changeReadingsStatus = (readingIds, statusId) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    dispatch(requestChangeReadingsStatus())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/reading/update-status`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        reading_ids: readingIds,
        status_id: statusId,
      },
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorChangeReadingsStatus('There was a problem loading the readings!'))

    const { err } = req
    if (err) return dispatch(errorChangeReadingsStatus(err.toString()))
    dispatch(receiveChangeReadingsStatus())
    dispatch(readings().refresh({ statusId: getState().readings.tab + 1 }))
  }
}
