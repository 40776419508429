import { getCommonActions } from './commonActions'
import config from '../config'
import * as request from 'request-promise-native'

export const SET_RESET_PASSWORD_STEP = 'SET_RESET_PASSWORD_STEP'
export const SET_ACTIVATE_ACCOUNT_STEP = 'SET_ACTIVATE_ACCOUNT_STEP'
export const SET_ADD_TENANT_ACCOUNT_STEP = 'SET_ADD_TENANT_ACCOUNT_STEP'
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD'
export const REQUEST_ACTIVATE_ACCOUNT = 'REQUEST_ACTIVATE_ACCOUNT'
export const REQUEST_ADD_TENANT_ACCOUNT = 'REQUEST_ADD_TENANT_ACCOUNT'
export const REQUEST_TENANT_ACCOUNTS = 'REQUEST_TENANT_ACCOUNTS'
export const REQUEST_SET_ACTIVE_TENANT_ACCOUNT = 'REQUEST_SET_ACTIVE_TENANT_ACCOUNT'
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD'
export const RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD'
export const RECEIVE_ACTIVATE_ACCOUNT = 'RECEIVE_ACTIVATE_ACCOUNT'
export const RECEIVE_ADD_TENANT_ACCOUNT = 'RECEIVE_ADD_TENANT_ACCOUNT'
export const RECEIVE_TENANT_ACCOUNTS = 'RECEIVE_TENANT_ACCOUNTS'
export const RECEIVE_SET_ACTIVE_TENANT_ACCOUNT = 'RECEIVE_SET_ACTIVE_TENANT_ACCOUNT'
export const ERROR_RESET_PASSWORD = 'ERROR_RESET_PASSWORD'
export const ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD'
export const ERROR_ACTIVATE_ACCOUNT = 'ERROR_ACTIVATE_ACCOUNT'
export const ERROR_ADD_TENANT_ACCOUNT = 'ERROR_ADD_TENANT_ACCOUNT'
export const ERROR_TENANT_ACCOUNTS = 'ERROR_TENANT_ACCOUNTS'
export const ERROR_SET_ACTIVE_TENANT_ACCOUNT = 'ERROR_SET_ACTIVE_TENANT_ACCOUNT'
export const USER_ACCOUNTS_SET_TAB = 'USER_ACCOUNTS_SET_TAB'

export const userAccounts = () =>
  getCommonActions('USER_ACCOUNTS', {
    mapState: state => state.userAccounts,
    endpoint: 'user',
    searchFields: [],
    labelFields: [],
  })

export const setResetPasswordStep = (userAccountId, step) => {
  return {
    type: SET_RESET_PASSWORD_STEP,
    id: userAccountId,
    step,
  }
}

const requestResetPassword = () => {
  return {
    type: REQUEST_RESET_PASSWORD,
  }
}

const receiveResetPassword = user => {
  return {
    type: RECEIVE_RESET_PASSWORD,
    user
  }
}

export const errorResetPassword = err => {
  return {
    type: ERROR_RESET_PASSWORD,
    err,
  }
}

const requestForgotPassword = () => {
  return {
    type: REQUEST_FORGOT_PASSWORD,
  }
}

const receiveForgotPassword = () => {
  return {
    type: RECEIVE_FORGOT_PASSWORD,
  }
}

export const errorForgotPassword = err => {
  return {
    type: ERROR_FORGOT_PASSWORD,
    err,
  }
}

export const errorActivateAccount = err => {
  return {
    type: ERROR_ACTIVATE_ACCOUNT,
    err,
  }
}

export const setActivateAccountStep = (userAccountId, step) => {
  return {
    type: SET_ACTIVATE_ACCOUNT_STEP,
    id: userAccountId,
    step,
  }
}

const requestActivateAccount = () => {
  return {
    type: REQUEST_ACTIVATE_ACCOUNT,
  }
}

const receiveActivateAccount = user => {
  return {
    type: RECEIVE_ACTIVATE_ACCOUNT,
    user,
  }
}

export const errorAddTenantAccount = err => {
  return {
    type: ERROR_ADD_TENANT_ACCOUNT,
    err,
  }
}

export const setAddTenantAccountStep = (userAccountId, step) => {
  return {
    type: SET_ADD_TENANT_ACCOUNT_STEP,
    id: userAccountId,
    step,
  }
}

const requestAddTenantAccount = () => {
  return {
    type: REQUEST_ADD_TENANT_ACCOUNT,
  }
}

const receiveAddTenantAccount = () => {
  return {
    type: RECEIVE_ADD_TENANT_ACCOUNT,
  }
}

const requestTenantAccounts = () => {
  return {
    type: REQUEST_TENANT_ACCOUNTS,
  }
}

const receiveTenantAccounts = tenants => {
  return {
    type: RECEIVE_TENANT_ACCOUNTS,
    tenants,
  }
}

export const errorTenantAccounts = err => {
  return {
    type: ERROR_TENANT_ACCOUNTS,
    err,
  }
}

const requestSetActiveTenantAccount = () => {
  return {
    type: REQUEST_SET_ACTIVE_TENANT_ACCOUNT,
  }
}

const receiveSetActiveTenantAccount = () => {
  return {
    type: RECEIVE_SET_ACTIVE_TENANT_ACCOUNT,
  }
}

export const errorSetActiveTenantAccount = err => {
  return {
    type: ERROR_SET_ACTIVE_TENANT_ACCOUNT,
    err,
  }
}

export const resetPassword = (fields, forgotPassword = false) => {
  return async (dispatch, getState) => {
    dispatch(requestResetPassword())

    const { loginToken } = getState().auth
    let options

    if (forgotPassword) {
      options = {
        method: 'POST',
        uri: `${config.baseUrl}/user/reset-password`,
        body: fields,
        json: true,
      }
    } else {
      const { password } = fields
      const { opened } = getState().userAccounts

      options = {
        method: 'PATCH',
        uri: `${config.baseUrl}/user/${opened.id}`,
        headers: {
          auth: JSON.stringify({ token: loginToken }),
        },
        body: {
          password,
        },
        json: true,
      }
    }

    const req = await request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorResetPassword(err.toString()))
    } else {
      dispatch(receiveResetPassword(res))
    }
  }
}

export const forgotPassword = (email) => {
  return async dispatch => {
    dispatch(requestForgotPassword())

    let options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/forgot-password`,
      body: {
        email,
      },
      json: true,
    }

    const req = await request(options)
    const { err } = req

    if (err) {
      dispatch(errorForgotPassword(err.toString()))
    } else {
      dispatch(receiveForgotPassword())
    }
  }
}

export const activateAccount = (password, registrationCode = null, name = null) => {
  return async (dispatch, getState) => {
    dispatch(requestActivateAccount())
    const { loginToken } = getState().auth
    let options
    if (registrationCode) {
      options = {
        method: 'POST',
        uri: `${config.baseUrl}/user/activate-account`,
        headers: {
          auth: JSON.stringify({ token: loginToken }),
        },
        body: {
          password,
          registrationCode,
          name,
        },
        json: true,
      }
    } else {
      const { opened } = getState().userAccounts

      options = {
        method: 'PATCH',
        uri: `${config.baseUrl}/user/${opened.id}`,
        headers: {
          auth: JSON.stringify({ token: loginToken }),
        },
        body: {
          password,
          name,
        },
        json: true,
      }
    }
    const req = await request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorActivateAccount(err.toString()))
    } else {
      dispatch(receiveActivateAccount(res))
    }
  }
}

export const setTab = tab => {
  return {
    type: USER_ACCOUNTS_SET_TAB,
    tab,
  }
}

export const addTenantAccount = (userAccountId, accountNumber) => {
  return async (dispatch, getState) => {
    dispatch(requestAddTenantAccount())

    const { loginToken } = getState().auth

    let options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/account/tenant`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        userAccountId,
        accountNumber,
      },
      json: true,
    }

    const req = await request(options)
    const { err } = req

    if (err) {
      dispatch(errorAddTenantAccount(err.toString()))
    } else {
      dispatch(receiveAddTenantAccount())
    }
  }
}

export const getTenantAccounts = userAccountId => {
  return async (dispatch, getState) => {
    dispatch(requestTenantAccounts())

    const { loginToken } = getState().auth

    let options = {
      method: 'GET',
      uri: `${config.baseUrl}/user/account/tenant/${userAccountId}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
    }

    const req = await request(options)
    const { err, res } = JSON.parse(req)

    if (err) {
      return dispatch(errorTenantAccounts(err.toString()))
    } else {
      return dispatch(receiveTenantAccounts(res))
    }
  }
}

export const setActiveTenantAccount = (userAccountId, tenantId) => {
  return async (dispatch, getState) => {
    dispatch(requestSetActiveTenantAccount())

    const { loginToken } = getState().auth

    let options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/account/tenant/active`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        userAccountId,
        tenantId,
      },
      json: true,
    }

    const req = await request(options)
    const { err } = req

    if (err) {
      return dispatch(errorSetActiveTenantAccount(err.toString()))
    } else {
      return dispatch(receiveSetActiveTenantAccount())
    }
  }
}
