import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('TENANTS', state, action)
  }
}

/*import {
  REQUEST_TENANTS,
  RECEIVE_TENANTS,
  ERROR_TENANTS,
  SELECT_PAGE_TENANTS,
  SET_CREATE_STEP_TENANT,
  REQUEST_CREATE_TENANT,
  RECEIVE_CREATE_TENANT,
  ERROR_CREATE_TENANT,
  CLEAR_CREATE_TENANT,
  REQUEST_TENANT,
  RECEIVE_TENANT,
  ERROR_TENANT,
  CLEAR_TENANT,
  SET_VIEW_STEP_TENANT,
  ENABLE_EDIT_TENANT,
  REQUEST_EDIT_TENANT,
  RECEIVE_EDIT_TENANT,
  ERROR_EDIT_TENANT
} from '../actions/tenantsActions'

const initialState = {
  list: [],
  selected: {
    id: null,
    accountNumber: null
  },
  page: null,
  perPage: 50,
  numPages: null,
  selectedPageList: [],
  createStep: 0,
  viewStep: 0,
  isFetchingSelected: false,
  errSelected: false,
  isCreating: false,
  errCreating: null,
  isFetching: false,
  errFetching: null,
  isEditing: false,
  requestEdit: false,
  errEditing: null
}

export default (state = initialState, action) => {
  const { tenant, tenants, page, step, err, type } = action
  let numPages, p, selectedPageList

  switch(type) {
    case REQUEST_TENANTS:
      return Object.assign({}, state, { isFetching: true, errFetching: null })

    case RECEIVE_TENANTS:
      numPages = Math.ceil(tenants.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = tenants.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list: tenants, isFetching: false, errFetching: null, page: p, numPages, selectedPageList })

    case ERROR_TENANTS:
      return Object.assign({}, state, { isFetching: false, errFetching: err })

    case SET_CREATE_STEP_TENANT:
      return Object.assign({}, state, { createStep: step })

    case REQUEST_CREATE_TENANT:
      return Object.assign({}, state, { isCreating: true, errCreating: null })

    case RECEIVE_CREATE_TENANT:
      const list = state.list
      list.push(tenant)
      numPages = Math.ceil(list.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = list.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list, isCreating: false, errCreating: null, createStep: 0, page: p, numPages, selectedPageList })

    case ERROR_CREATE_TENANT:
      return Object.assign({}, state, { isCreating: false, errCreating: err })

    case CLEAR_CREATE_TENANT:
      return Object.assign({}, state, { isCreating: false, errCreating: null })

    case SELECT_PAGE_TENANTS:
      return Object.assign({}, state, { page, selectedPageList: state.list.slice((page-1) * state.perPage, page * state.perPage) })

    case REQUEST_TENANT:
      return Object.assign({}, state, { isFetchingSelected: true, errSelected: null })

    case RECEIVE_TENANT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: null, selected: tenant })

    case ERROR_TENANT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: err })

    case CLEAR_TENANT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: false, selected: initialState.selected })

    case SET_VIEW_STEP_TENANT:
      return Object.assign({}, state, { viewStep: step })

    case ENABLE_EDIT_TENANT:
      return Object.assign({}, state, { isEditing: true, errEditing: null })

    case REQUEST_EDIT_TENANT:
      return Object.assign({}, state, { requestEdit: true, errEditing: null })

    case RECEIVE_EDIT_TENANT:
      return Object.assign({}, state, { isEditing: false, requestEdit: false, selected: tenant, errEditing: null })

    case ERROR_EDIT_TENANT:
      return Object.assign({}, state, { isEditing: false, requestEdit: false, errEditing: err })

    default:
      return state
  }
}*/
