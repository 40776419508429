import { getCommonActions } from './commonActions'
import config from '../config'
import * as request from 'request-promise-native'

export const REQUEST_UNIT_AVAILABILITY = 'REQUEST_UNIT_AVAILABILITY'
export const RECEIVE_UNIT_AVAILABILITY = 'RECEIVE_UNIT_AVAILABILITY'
export const ERROR_UNIT_AVAILABILITY = 'ERROR_UNIT_AVAILABILITY'

export const units = () =>
  getCommonActions('UNITS', {
    mapState: state => state.units,
    endpoint: 'unit',
    searchFields: ['number'],
    labelFields: ['number'],
  })

const requestUnitAvailability = () => {
  return {
    type: REQUEST_UNIT_AVAILABILITY,
  }
}

const receiveUnitAvailability = date => {
  return {
    type: RECEIVE_UNIT_AVAILABILITY,
    date,
  }
}

const errorUnitAvailability = err => {
  return {
    type: ERROR_UNIT_AVAILABILITY,
    err,
  }
}

export const getUnitAvailability = id => {
  return async (dispatch, getState) => {
    dispatch(requestUnitAvailability())
    const { loginToken } = getState().auth

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/tenant?fields=${encodeURIComponent(JSON.stringify({ unitId: id }))}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const { err, res } = await request(options)

    if (err) {
      return dispatch(errorUnitAvailability(err.toString()))
    }

    let date = null

    res.forEach(t => {
      if (t.moveOutDate) {
        const moveOutDate = new Date(t.moveOutDate)
        if (!date) {
          date = moveOutDate
        } else if (moveOutDate > date) {
          date = moveOutDate
        }
      }
    })

    return dispatch(receiveUnitAvailability(date))
  }
}

export const loadOptionsWithMeters = (args = [], additionalOptions = []) => async (search, loadedOptions, { page }) => {
  const loginToken = localStorage.getItem('loginToken')
  const perPage = 25

  const fields = args.fields ? typeof args.fields === 'string' ? JSON.parse(decodeURIComponent(args.fields)) : args.fields : {}

  search = search ? { number: `%${search}%` } : {}

  const numRowsOptions = {
    method: 'GET',
    uri: `${config.baseUrl}/unit/with-meters-num-rows?search=${encodeURIComponent(JSON.stringify(search))}&fields=${encodeURIComponent(JSON.stringify(fields))}`,
    headers: {
      auth: JSON.stringify({ token: loginToken }),
    },
    json: true,
  }

  const { res: num, err: errNum } = await request(numRowsOptions)

  if (!errNum && (num || num === 0)) {
    const numPages = Math.ceil(num / perPage)

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/unit/with-meters?page=${page}&perPage=${perPage}&search=${encodeURIComponent(JSON.stringify(search))}&fields=${encodeURIComponent(JSON.stringify(fields))}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const { res: units, err } = await request(options)

    if (!err && units) {
      const hasMore = page < numPages

      const options = units.map(u => ({ label: `${u.number}${u.meterNumbers ? ` (${u.meterNumbers})` : ''}`, value: u.id }))
      additionalOptions.length && options.unshift(...additionalOptions)

      return {
        options,
        hasMore,
        additional: {
          page: page + 1
        }
      }
    }
  }
}
