import { grid, pagination, viewEdit } from './commonStates'

import { commonReducer } from './commonReducer'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  selectedOption: {
    value: 0,
    label: 'Active',
  },
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('CHARGE_CODES', state, action)
  }
}
