import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import {
  ERROR_SELECT_USER_ACCOUNT_MANAGEMENT,
  RECEIVE_SELECT_USER_ACCOUNT_MANAGEMENT,
  REQUEST_SELECT_USER_ACCOUNT_MANAGEMENT,
} from '../actions/userAccountManagementsActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  selected: null,
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  case REQUEST_SELECT_USER_ACCOUNT_MANAGEMENT:
    return Object.assign({}, state, { isFetching: true, errFetching: false })
  case RECEIVE_SELECT_USER_ACCOUNT_MANAGEMENT:
    return Object.assign({}, state, { isFetching: false, errFetching: false, selected: action.managementId })
  case ERROR_SELECT_USER_ACCOUNT_MANAGEMENT:
    return Object.assign({}, state, { isFetching: false, errFetching: true })
  default:
    return commonReducer('USER_ACCOUNT_MANAGEMENT', state, action)
  }
}
