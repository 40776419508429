import { getCommonActions } from './commonActions'
import config from '../config'
import * as request from 'request-promise-native'

export const REQUEST_DOWNLOAD_PAYMENTS_REPORT = 'REQUEST_DOWNLOAD_PAYMENTS_REPORT'
export const RECEIVE_DOWNLOAD_PAYMENTS_REPORT = 'RECEIVE_DOWNLOAD_PAYMENTS_REPORT'
export const ERROR_DOWNLOAD_PAYMENTS_REPORT = 'ERROR_DOWNLOAD_PAYMENTS_REPORT'

export const payments = () =>
  getCommonActions('PAYMENTS', {
    mapState: p => p.payments,
    endpoint: 'payment',
  })

const requestDownloadPaymentsReport = () => ({
  type: REQUEST_DOWNLOAD_PAYMENTS_REPORT,
})
const receiveDownloadPaymentsReport = () => ({
  type: RECEIVE_DOWNLOAD_PAYMENTS_REPORT,
})
const errorDownloadPaymentsReport = err => ({
  type: ERROR_DOWNLOAD_PAYMENTS_REPORT,
  err,
})

export const downloadPaymentsReport = (buildingNumber, fields) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestDownloadPaymentsReport())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/reports/payment`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: fields,
      resolveWithFullResponse: true,
      json: true,
    }

    const { body } = await request(options)

    if (!body) return dispatch(errorDownloadPaymentsReport('There was a problem downloading the payments report!'))

    if ('err' in body) {
      return dispatch(errorDownloadPaymentsReport(body.err))
    }

    const blob = new Blob([body], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')

    document.body.appendChild(link)

    link.style = 'display: none'
    link.href = url
    link.download = `${buildingNumber} Payment Report.pdf`
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 100)

    dispatch(receiveDownloadPaymentsReport())
  }
}

