import {
  REQUEST_ORGANIZATION,
  RECEIVE_ORGANIZATION,
  REQUEST_EDIT_ORG,
  ERROR_EDIT_ORG,
  ERROR_FETCHING,
} from '../actions/organizationsActions'

const initialState = {
  id: null,
  email: null,
  name: null,
  phone: null,
  website: null,
  isFetching: false,
  isEditing: false,
  errFetching: false,
  errEditing: false,
  err: null,
}

const organizations = (state = initialState, action) => {
  const {data, err} = action

  switch (action.type){
  case REQUEST_ORGANIZATION:
    return Object.assign({}, state, { isFetching: true })
  case REQUEST_EDIT_ORG:
    return Object.assign({},state, { isFetching: true })
  case RECEIVE_ORGANIZATION:
    return Object.assign({},state,{...data, isFetching: false})
  case ERROR_EDIT_ORG:
    return Object.assign({},state,{isFetching: false, errEditing: err})
  case ERROR_FETCHING:
    return Object.assign({},state,{isFetching: false, errFetching: err})
  default:
    return state
  }

}

export default organizations
