import { commonReducer } from './commonReducer'
import { grid, pagination } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('UTILITY_TYPES', state, action)
  }
}
