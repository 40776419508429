import * as request from 'request-promise-native'
import config from '../config'
import { getCommonActions } from './commonActions'

export const CHARGES_SET_TAB = 'CHARGES_SET_TAB'
export const REQUEST_DUPLICATE_CHARGES = 'REQUEST_DUPLICATE_CHARGES'
export const RECEIVE_DUPLICATE_CHARGES = 'RECEIVE_DUPLICATE_CHARGES'
export const ERROR_DUPLICATE_CHARGES = 'ERROR_DUPLICATE_CHARGES'

const requestDuplicateCharges = () => {
  return {
    type: REQUEST_DUPLICATE_CHARGES,
  }
}

const receiveDuplicateCharges = charge => {
  return {
    type: RECEIVE_DUPLICATE_CHARGES,
    charge,
  }
}

const errorDuplicateCharges = err => {
  return {
    type: ERROR_DUPLICATE_CHARGES,
    err,
  }
}

export const charges = () =>
  getCommonActions('CHARGES', {
    mapState: state => state.charges,
    endpoint: 'charge',
    searchFields: ['number'],
    labelFields: ['number'],
  })

export const duplicateCharges = ids => {
  return async (dispatch, getState) => {
    dispatch(requestDuplicateCharges())
    const { loginToken } = getState().auth
    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/charge/duplicate`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: { ids },
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorDuplicateCharges('There was a problem loading the charges!'))

    const { err, res } = req
    if (err) return dispatch(errorDuplicateCharges(err.toString()))
    else return dispatch(receiveDuplicateCharges(res))
  }
}

export const setTab = tab => {
  return {
    type: CHARGES_SET_TAB,
    tab,
  }
}
