import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('ADDRESS', state, action)
  }
}

/*import {
  ERROR_CREATE_ADDRESS,
  RECEIVE_CREATE_ADDRESS,
  REQUEST_CREATE_ADDRESS,
  CLEAR_ADDRESS
} from '../actions/addressActions'

const initialState = {
  id: null,
  isCreating: false,
  err: null
}

export default (state = initialState, action) => {
  const { address, err, type } = action

  switch(type) {
    case REQUEST_CREATE_ADDRESS:
      return Object.assign({}, state, { isCreating: true, err: null })

    case RECEIVE_CREATE_ADDRESS:
      return Object.assign({}, state, { id: address.id, isCreating: false, err: null })

    case ERROR_CREATE_ADDRESS:
      return Object.assign({}, state, { isCreating: false, err })

    case CLEAR_ADDRESS:
      return initialState

    default:
      return state
  }
}*/
