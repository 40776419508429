import {
  REQUEST_TENANT_DASHBOARD_VALUES,
  RECEIVE_TENANT_DASHBOARD_VALUES,
  ERROR_TENANT_DASHBOARD_VALUES,
  CLEAR_TENANT_DASHBOARD_VALUES,
} from '../actions/tenantDashboardActions'
import { commonReducer } from './commonReducer'

const initialState = {
  totalBalance: 0,
  previousBills: [],
  amountOverdue: 0,
  creditCardFees: null,
  progress: 0,
  errFetching: null,
  isFetching: false,
  paymentCustomAmount: false,
}

export default (state = initialState, action) => {
  const { type, err, values } = action

  switch (type) {
  case REQUEST_TENANT_DASHBOARD_VALUES:
    return Object.assign({}, state, {
      isFetching: true,
      previousBills: [],
    })
  case RECEIVE_TENANT_DASHBOARD_VALUES:
    return Object.assign({}, state, {
      isFetching: false,
      errFetching: null,
      ...values,
    })
  case ERROR_TENANT_DASHBOARD_VALUES:
    return Object.assign({}, state, {
      errFetching: err,
    })
  case CLEAR_TENANT_DASHBOARD_VALUES:
    return Object.assign({}, state, {
      ...initialState,
    })
  default:
    return commonReducer('TENANT_DASHBOARD', state, action)
  }
}
