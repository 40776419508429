import {
  BILLS_SET_TAB,
  REQUEST_GENERATE_BILLS,
  RECEIVE_GENERATE_BILLS,
  ERROR_GENERATE_BILLS,
  BILLS_CLEAR_MESSAGES,
  REQUEST_PRINT_BILLS,
  RECEIVE_PRINT_BILLS,
  ERROR_PRINT_BILLS,
  REQUEST_SEND_BILLS,
  RECEIVE_SEND_BILLS,
  ERROR_SEND_BILLS,
  REQUEST_UNDO_SEND_BILLS,
  RECEIVE_UNDO_SEND_BILLS,
  ERROR_UNDO_SEND_BILLS,
  REQUEST_CURRENT_ACCOUNT_BILL,
  RECEIVE_CURRENT_ACCOUNT_BILL,
  ERROR_CURRENT_ACCOUNT_BILL,
  CLEAR_CURRENT_ACCOUNT_BILL,
  SET_PAYMENT_STEP,
  REQUEST_TENANT_SUBMIT_PAYMENT,
  RECEIVE_TENANT_SUBMIT_PAYMENT,
  ERROR_TENANT_SUBMIT_PAYMENT,
  CLEAR_TENANT_SUBMIT_PAYMENT,
  SET_RESOLVE_BILL_STEP,
  REQUEST_RESOLVE_BILL,
  RECEIVE_RESOLVE_BILL,
  ERROR_RESOLVE_BILL,
  REQUEST_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
  RECEIVE_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
  ERROR_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
  REQUEST_BATCH_PRINT_BILLS,
  RECEIVE_BATCH_PRINT_BILLS,
  ERROR_BATCH_PRINT_BILLS,
} from '../actions/billsActions'
import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...viewEdit(),
  tab: null,
  tabs: {
    0: {
      ...grid(),
      ...pagination(),
    },
    1: {
      ...grid(),
      ...pagination(),
    },
    2: {
      ...grid(),
      ...pagination(),
    },
  },
  errGenerating: null,
  messageGenerating: null,
  isCreating: false,
  isPrinting: false,
  errPrinting: null,
  isSending: false,
  errSending: null,
  currentAccountBill: null,
  paymentStep: 0,
  tenantSubmittingPayment: false,
  errTenantSubmitPayment: null,
  tenantPayment: null,
  resolveBillStep: 0,
  errResolveBill: null,
  isResolving: false,
  isDownloadingBillLineDetailReport: false,
  errDownloadingBillLineDetailReport: null,
  isBills: true,
  isBatchPrinting: false,
  errBatchPrinting: null,
}

export default (state = initialState, action) => {
  const { type, tab, message, err, bill, step, payment } = action

  switch (type) {
  case BILLS_SET_TAB:
    return Object.assign({}, state, {
      tab,
      selected: [],
    })
  case REQUEST_GENERATE_BILLS:
    return Object.assign({}, state, {
      isCreating: true,
      errGenerating: null,
      messageGenerating: null,
    })
  case RECEIVE_GENERATE_BILLS:
    return Object.assign({}, state, {
      isCreating: false,
      errGenerating: null,
      messageGenerating: message,
    })
  case ERROR_GENERATE_BILLS:
    return Object.assign({}, state, {
      isCreating: false,
      errGenerating: err,
      messageGenerating: null,
    })
  case BILLS_CLEAR_MESSAGES:
    return Object.assign({}, state, {
      messageGenerating: null,
    })
  case REQUEST_PRINT_BILLS:
    return Object.assign({}, state, {
      isPrinting: true,
      errPrinting: null,
    })
  case RECEIVE_PRINT_BILLS:
    return Object.assign({}, state, {
      isPrinting: false,
      errPrinting: null,
    })
  case ERROR_PRINT_BILLS:
    return Object.assign({}, state, {
      isPrinting: false,
      errPrinting: err,
    })
  case REQUEST_SEND_BILLS:
  case REQUEST_UNDO_SEND_BILLS:
    return Object.assign({}, state, {
      isSending: true,
      errSending: null,
    })
  case RECEIVE_SEND_BILLS:
  case RECEIVE_UNDO_SEND_BILLS:
    return Object.assign({}, state, {
      isSending: false,
      errSending: null,
    })
  case ERROR_SEND_BILLS:
  case ERROR_UNDO_SEND_BILLS:
    return Object.assign({}, state, {
      isSending: false,
      errSending: err,
    })
  case REQUEST_CURRENT_ACCOUNT_BILL:
    return Object.assign({}, state, {
      isFetching: true,
      errFetching: null,
      currentAccountBill: null,
    })
  case RECEIVE_CURRENT_ACCOUNT_BILL:
    return Object.assign({}, state, {
      isFetching: false,
      errFetching: null,
      currentAccountBill: bill,
    })
  case ERROR_CURRENT_ACCOUNT_BILL:
    return Object.assign({}, state, {
      isFetching: false,
      errFetching: err,
      currentAccountBill: null,
    })
  case CLEAR_CURRENT_ACCOUNT_BILL:
    return Object.assign({}, state, {
      currentAccountBill: null,
    })
  case SET_PAYMENT_STEP:
    return Object.assign({}, state, { paymentStep: step })
  case REQUEST_TENANT_SUBMIT_PAYMENT:
    return { ...state, tenantSubmittingPayment: true }
  case RECEIVE_TENANT_SUBMIT_PAYMENT:
    return { ...state, tenantSubmittingPayment: false, tenantPayment: payment, paymentStep: 0 }
  case ERROR_TENANT_SUBMIT_PAYMENT:
    return { ...state, tenantSubmittingPayment: false, tenantPayment: null, errTenantSubmitPayment: err }
  case CLEAR_TENANT_SUBMIT_PAYMENT:
    return { ...state, tenantSubmittingPayment: false, errTenantSubmitPayment: null, tenantPayment: null }
  case SET_RESOLVE_BILL_STEP:
    return Object.assign({}, state, { resolveBillStep: step })
  case REQUEST_RESOLVE_BILL:
    return { ...state, isResolving: true, errResolveBill: null }
  case RECEIVE_RESOLVE_BILL:
    return { ...state, isResolving: false, opened: bill }
  case ERROR_RESOLVE_BILL:
    return { ...state, isResolving: false, errResolveBill: err }
  case REQUEST_DOWNLOAD_BILL_LINE_DETAIL_REPORT:
    return Object.assign({}, state, {
      isDownloadingBillLineDetailReport: true,
      errDownloadingBillLineDetailReport: null,
    })
  case RECEIVE_DOWNLOAD_BILL_LINE_DETAIL_REPORT:
    return Object.assign({}, state, {
      isDownloadingBillLineDetailReport: false,
      errDownloadingBillLineDetailReport: null,
    })
  case ERROR_DOWNLOAD_BILL_LINE_DETAIL_REPORT:
    return Object.assign({}, state, {
      isDownloadingBillLineDetailReport: false,
      errDownloadingBillLineDetailReport: err,
    })
  case REQUEST_BATCH_PRINT_BILLS:
    return Object.assign({}, state, {
      isBatchPrinting: true,
      errBatchPrinting: null,
    })
  case RECEIVE_BATCH_PRINT_BILLS:
    return Object.assign({}, state, {
      isBatchPrinting: false,
      errBatchPrinting: null,
    })
  case ERROR_BATCH_PRINT_BILLS:
    return Object.assign({}, state, {
      isBatchPrinting: false,
      errBatchPrinting: err,
    })
  default:
    return commonReducer('BILLS', state, action)
  }
}
