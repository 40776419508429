//WARNING: Common state objects must be returned via function call. This ensures deep copies of each initial state

export const grid = () => {
  return {
    data: [],
    isFetching: false,
    isFetchingPage: false,
    errFetching: null,
    isCreating: false,
    errCreating: null,
    isDeleting: false,
    errDeleting: null,
    selected: [],
    createStep: 0,
    filters: null,
    selectedOption: null,
  }
}

export const pagination = () => {
  return {
    perPage: 50,
    numPages: null,
    page: 1,
    isFetchingNumPages: false,
    errFetchingNumPages: null,
    numRows: 0
  }
}

export const viewEdit = () => {
  return {
    opened: {},
    isEditing: false,
    errEditing: null,
    viewStep: 0,
  }
}
