import { getCommonActions } from './commonActions'
import config from '../config'
import * as request from 'request-promise-native'

export const managements = () =>
  getCommonActions('MANAGEMENTS', {
    mapState: s => s.managements,
    endpoint: 'management',
    searchFields: ['number', 'name'],
    labelFields: ['number', 'name'],
  })

export const REQUEST_CONNECT_ACCOUNT = 'REQUEST_CONNECT_ACCOUNT'
export const RECEIVE_CONNECT_ACCOUNT = 'RECEIVE_CONNECT_ACCOUNT'
export const CLEAR_CONNECT_ACCOUNT = 'CLEAR_CONNECT_ACCOUNT'
export const NO_CONNECT_ACCOUNT = 'NO_CONNECT_ACCOUNT'
export const ERROR_CONNECT_ACCOUNT = 'ERROR_CONNECT_ACCOUNT'
export const CLEAR_ERROR_CONNECT_ACCOUNT = 'CLEAR_ERROR_CONNECT_ACCOUNT'
export const REQUEST_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT = 'REQUEST_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT'
export const RECEIVE_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT = 'RECEIVE_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT'
export const ERROR_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT = 'ERROR_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT'
export const REQUEST_DOWNLOAD_HVAC_LOOP_BILLS = 'REQUEST_DOWNLOAD_HVAC_LOOP_BILLS'
export const RECEIVE_DOWNLOAD_HVAC_LOOP_BILLS = 'RECEIVE_DOWNLOAD_HVAC_LOOP_BILLS'
export const ERROR_DOWNLOAD_HVAC_LOOP_BILLS = 'ERROR_DOWNLOAD_HVAC_LOOP_BILLS'
export const REQUEST_DOWNLOAD_AGING_REPORT = 'REQUEST_DOWNLOAD_AGING_REPORT'
export const RECEIVE_DOWNLOAD_AGING_REPORT = 'RECEIVE_DOWNLOAD_AGING_REPORT'
export const ERROR_DOWNLOAD_AGING_REPORT = 'ERROR_DOWNLOAD_AGING_REPORT'

const requestConnectAccount = () => {
  return {
    type: REQUEST_CONNECT_ACCOUNT,
  }
}
const clearConnectAccount = () => {
  return {
    type: CLEAR_CONNECT_ACCOUNT,
  }
}
const noConnectAccount = () => {
  return {
    type: NO_CONNECT_ACCOUNT,
  }
}
const receiveConnectAccount = account => {
  return {
    type: RECEIVE_CONNECT_ACCOUNT,
    account,
  }
}
const errorConnectAccount = err => {
  return {
    type: ERROR_CONNECT_ACCOUNT,
    err,
  }
}
export const clearErrorConnectAccount = () => {
  return {
    type: CLEAR_ERROR_CONNECT_ACCOUNT,
  }
}
export const editConnectAccount = connectAccount => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/stripe/management/createOrUpdate`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
        // 'x-forwarded-for': '99.113.239.30', for testing....
      },
      body: connectAccount,
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorConnectAccount('Something went wrong updating your payout information.'))
    const { err, res } = req

    if (err) {
      dispatch(errorConnectAccount(typeof err === 'string' ? err : err.toString()))
    } else {
      dispatch(receiveConnectAccount(res))
    }
  }
}

export const getGetConnectById = (id = '') => {
  return async (dispatch, getState) => {
    dispatch(clearConnectAccount())
    const { loginToken } = getState().auth
    dispatch(requestConnectAccount())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/stripe/management/${id}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await request(options)
    if (!req) return
    const { err, res: connectAccount } = req

    if (err) {
      dispatch(noConnectAccount())
    } else {
      dispatch(receiveConnectAccount(connectAccount))
    }
  }
}

const requestDownloadFormalManagementSummariesReport = () => {
  return {
    type: REQUEST_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
  }
}

const receiveDownloadFormalManagementSummariesReport = () => {
  return {
    type: RECEIVE_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
  }
}

const errorDownloadFormalManagementSummariesReport = err => {
  return {
    type: ERROR_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
    err,
  }
}

export const downloadFormalManagementSummariesReport = (managementNumber, fields) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    const { fileType } = fields

    dispatch(requestDownloadFormalManagementSummariesReport())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/reports/management-summary`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: fields,
      resolveWithFullResponse: true,
      json: true,
    }

    const { body, headers } = await request(options)
    if (!body || headers['content-type'] !== fileType) dispatch(errorDownloadFormalManagementSummariesReport('There was a problem downloading the report!'))

    if ('err' in body) {
      return dispatch(errorDownloadFormalManagementSummariesReport(body.err))
    }

    const blob = []
    if (headers['content-type'] === 'csv') {
      body.forEach(b => {
        let buildingNumber = b.match(/Building No,(.*?)\n/)
        blob.push({
          blob: new Blob([b], { type: `application/${fileType}` }),
          fileName: `${buildingNumber ? `${buildingNumber.pop()}_` : ''}${managementNumber ? `${managementNumber}_` : ''}${fields.endDate.getMonth() + 1}_${fields.endDate.getFullYear()}_Management_Summary.${fileType}`
        })
      })
    } else {
      blob.push({
        blob: new Blob([body], { type: `application/${fileType}` }),
        fileName: `${managementNumber ? `${managementNumber}_` : ''}${fields.endDate.getMonth() + 1}_${fields.endDate.getFullYear()}_Management_Summary.${fileType}`
      })
    }

    blob.forEach((b, index) => {
      //This time out is needed for downloading multiple files on Safari, which requires staggered downloads rather than concurrent.
      setTimeout(() => {
        const url = window.URL.createObjectURL(b.blob)

        let link = document.createElement('a')

        document.body.appendChild(link)

        link.style = 'display: none'
        link.href = url
        link.download = b.fileName
        link.click()
        setTimeout(() => {
          return window.URL.revokeObjectURL(url)
        }, 100)
      }, 100 * index)
    })

    dispatch(receiveDownloadFormalManagementSummariesReport())
  }
}

const requestDownloadHvacLoopBills = () => {
  return {
    type: REQUEST_DOWNLOAD_HVAC_LOOP_BILLS,
  }
}

const receiveDownloadHvacLoopBills = () => {
  return {
    type: RECEIVE_DOWNLOAD_HVAC_LOOP_BILLS,
  }
}

const errorDownloadHvacLoopBills = err => {
  return {
    type: ERROR_DOWNLOAD_HVAC_LOOP_BILLS,
    err,
  }
}

export const downloadHvacLoopBills = (id, management, download = false, fields = null) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    if (!id) dispatch(errorDownloadHvacLoopBills('There was a problem downloading the bill!'))
    dispatch(requestDownloadHvacLoopBills())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/reports/management-summary/hvac-loop-bills`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        id,
        fields,
      },
      resolveWithFullResponse: true,
      json: true,
    }

    const { body, headers } = await request(options)
    if (!body || headers['content-type'] !== 'pdf') dispatch(errorDownloadHvacLoopBills('There was a problem downloading the bill!'))

    if ('err' in body) {
      return dispatch(errorDownloadHvacLoopBills(body.err))
    }

    const blob = new Blob([body], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    var link = document.createElement('a')

    if (download) {
      document.body.appendChild(link)
      link.style = 'display: none'
      link.href = url
      link.download = `${management ? `${management}_` : ''}HVAC_Loop_Summary.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 100)
    } else {
      link.href = url
      link.target = '_self'
      link.click()
    }

    dispatch(receiveDownloadHvacLoopBills())
  }
}

const requestDownloadAgingReport = () => {
  return {
    type: REQUEST_DOWNLOAD_AGING_REPORT,
  }
}

const receiveDownloadAgingReport = () => {
  return {
    type: RECEIVE_DOWNLOAD_AGING_REPORT,
  }
}

const errorDownloadAgingReport = err => {
  return {
    type: ERROR_DOWNLOAD_AGING_REPORT,
    err,
  }
}

export const downloadAgingReport = (managementNumber, fields) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    const fileType = 'pdf'

    dispatch(requestDownloadAgingReport())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/reports/management-summary/aging`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: fields,
      resolveWithFullResponse: true,
      json: true,
    }

    const { body, headers } = await request(options)
    if (!body || headers['content-type'] !== fileType) dispatch(errorDownloadAgingReport('There was a problem downloading the report!'))

    if ('err' in body) {
      return dispatch(errorDownloadAgingReport(body.err))
    }

    const blob = []

    blob.push({
      blob: new Blob([body], { type: `application/${fileType}` }),
      fileName: `${managementNumber ? `${managementNumber}_` : ''}${new Date().getMonth() + 1}_${new Date().getFullYear()}_Aging.${fileType}`
    })

    blob.forEach((b, index) => {
      //This time out is needed for downloading multiple files on Safari, which requires staggered downloads rather than concurrent.
      setTimeout(() => {
        const url = window.URL.createObjectURL(b.blob)

        let link = document.createElement('a')

        document.body.appendChild(link)

        link.style = 'display: none'
        link.href = url
        link.download = b.fileName
        link.click()
        setTimeout(() => {
          return window.URL.revokeObjectURL(url)
        }, 100)
      }, 100 * index)
    })

    dispatch(receiveDownloadAgingReport())
  }
}

/* import * as request from 'request-promise-native'
import config from '../config'

export const REQUEST_MANAGEMENT = 'REQUEST_MANAGEMENT'
export const RECEIVE_MANAGEMENT = 'RECEIVE_MANAGEMENT'
export const ERROR_MANAGEMENT = 'ERROR_MANAGEMENT'
export const CLEAR_MANAGEMENT = 'CLEAR_MANAGEMENT'
export const REQUEST_MANAGEMENTS = 'REQUEST_MANAGEMENTS'
export const RECEIVE_MANAGEMENTS = 'RECEIVE_MANAGEMENTS'
export const ERROR_MANAGEMENTS = 'ERROR_MANAGEMENTS'
export const SELECT_PAGE_MANAGEMENTS = 'SELECT_PAGE_MANAGEMENTS'
export const SET_CREATE_STEP_MANAGEMENT = 'SET_CREATE_STEP_MANAGEMENT'
export const REQUEST_CREATE_MANAGEMENT = 'REQUEST_CREATE_MANAGEMENT'
export const RECEIVE_CREATE_MANAGEMENT = 'RECEIVE_CREATE_MANAGEMENT'
export const ERROR_CREATE_MANAGEMENT = 'ERROR_CREATE_MANAGEMENT'
export const CLEAR_CREATE_MANAGEMENT = 'CLEAR_CREATE_MANAGEMENT'
export const SET_VIEW_STEP_MANAGEMENT = 'SET_VIEW_STEP_MANAGEMENT'
export const ENABLE_EDIT_MANAGEMENT = 'ENABLE_EDIT_MANAGEMENT'
export const REQUEST_EDIT_MANAGEMENT = 'REQUEST_EDIT_MANAGEMENT'
export const RECEIVE_EDIT_MANAGEMENT = 'RECEIVE_EDIT_MANAGEMENT'
export const ERROR_EDIT_MANAGEMENT = 'ERROR_EDIT_MANAGEMENT'

const requestManagement = () => {
  return {
    type: REQUEST_MANAGEMENT
  }
}

const receiveManagement = management => {
  return {
    type: RECEIVE_MANAGEMENT,
    management
  }
}

export const errorManagement = err => {
  return {
    type: ERROR_MANAGEMENT,
    err
  }
}

export const clearManagement = () => {
  return {
    type: CLEAR_MANAGEMENT
  }
}

const requestManagements = () => {
  return {
    type: REQUEST_MANAGEMENTS
  }
}

const receiveManagements = managements => {
  return {
    type: RECEIVE_MANAGEMENTS,
    managements
  }
}

const errorManagements = err => {
  return {
    type: ERROR_MANAGEMENTS,
    err
  }
}

export const selectPageManagements = page => {
  return {
    type: SELECT_PAGE_MANAGEMENTS,
    page
  }
}

export const setCreateStepManagement = step => {
  return {
    type: SET_CREATE_STEP_MANAGEMENT,
    step
  }
}

export const requestCreateManagement = () => {
  return {
    type: REQUEST_CREATE_MANAGEMENT
  }
}

const receiveCreateManagement = management => {
  return {
    type: RECEIVE_CREATE_MANAGEMENT,
    management
  }
}

export const errorCreateManagement = err => {
  return {
    type: ERROR_CREATE_MANAGEMENT,
    err
  }
}

export const clearCreateManagement = () => {
  return {
    type: CLEAR_CREATE_MANAGEMENT
  }
}

export const setViewStepManagement = step => {
  return {
    type: SET_VIEW_STEP_MANAGEMENT,
    step
  }
}

export const enableEditManagement = () => {
  return {
    type: ENABLE_EDIT_MANAGEMENT
  }
}

const requestEditManagement = () => {
  return {
    type: REQUEST_EDIT_MANAGEMENT
  }
}

const receiveEditManagement = management => {
  return {
    type: RECEIVE_EDIT_MANAGEMENT,
    management
  }
}

const errorEditManagement = err => {
  return {
    type: ERROR_EDIT_MANAGEMENT,
    err
  }
}

export const getAllManagements = token => {
  return async dispatch => {
    dispatch(requestManagements())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/customers/management`,
      headers: {
        auth: JSON.stringify({ token })
      },
      json: true
    }

    const req = await request(options)
    if (!req) return dispatch(errorManagements())
    const { err, res: managements } = req

    if (err) {
      dispatch(errorManagements(err.toString()))
    } else {
      dispatch(receiveManagements(managements))
    }
  }
}

export const getManagementById = (id, token) => {
  return async dispatch => {
    if (id < 0) return dispatch(clearManagement())
    dispatch(requestManagement())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/customers/management/${id}`,
      headers: {
        auth: JSON.stringify({ token })
      },
      json: true
    }

    const req = await request(options)
    if (!req) return dispatch(errorManagement())
    const { err, res: management } = req

    if (err) {
      dispatch(errorManagement(err.toString()))
    } else {
      dispatch(receiveManagement(management[0]))
    }
  }
}

export const createManagement = (management, token) => {
  return async dispatch => {
    dispatch(requestCreateManagement())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/customers/management`,
      headers: {
        auth: JSON.stringify({ token })
      },
      body: management,
      json: true
    }

    const req = await request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorCreateManagement(err.toString()))
    } else {
      dispatch(receiveCreateManagement(res))
    }
  }
}

export const editManagement = (management, token) => {
  return async dispatch => {
    dispatch(requestEditManagement())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/customers/management/${management.id}`,
      headers: {
        auth: JSON.stringify({ token })
      },
      body: management,
      json: true
    }

    const req = await request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorEditManagement(err.toString()))
    } else {
      dispatch(receiveEditManagement(res))
    }
  }
}*/
