const reducer = (state, action) => {
  const { type, err, errNumPages, record, step, numRows, page, data, recordId, isSelected, filter, option, perPage, tab: refreshedTab } = action
  let numPages, d, filters

  switch (type) {
  case 'SET_SELECTED_OPTION':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      const t = Object.assign({}, tab, { selectedOption: option })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    return Object.assign({}, state, { selectedOption: option })
  case 'ADD_FILTER':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      filters = Object.assign({}, tab.filters, { ...filter })
      const t = Object.assign({}, tab, { filters })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    filters = Object.assign({}, state.filters, { ...filter })
    return Object.assign({}, state, { filters })
  case 'REMOVE_FILTER':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      filters = tab.filters
      if (filters === null) return state
      filter.forEach(key => delete filters[key])
      filters = Object.keys(filters).length < 1 ? null : filters
      const t = Object.assign({}, tab, { filters })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    filters = state.filters
    if (filters === null) return state
    filter.forEach(key => delete filters[key])
    filters = Object.keys(filters).length < 1 ? null : filters
    return Object.assign({}, state, { filters })
  case 'UPDATE_FILTER':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      filters = tab.filters
      if (filters === null) return state
      Object.keys(filter).forEach(key => (filters[key] = filter[key]))
      const t = Object.assign({}, tab, { filters })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    filters = state.filters
    if (filters === null) return state
    Object.keys(filter).forEach(key => (filters[key] = filter[key]))
    return Object.assign({}, state, { filters })
  case 'CLEAR_FILTERS':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      const t = Object.assign({}, tab, { filters: null })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    return Object.assign({}, state, { filters: null })
  case 'SET_CREATE_STEP':
    return Object.assign({}, state, { createStep: step })
  case 'SET_VIEW_STEP':
    let opened = state.opened
    if (step < 1) {
      opened = {}
    }
    return Object.assign({}, state, { viewStep: step, opened })
  case 'RECEIVE_CREATE_RECORD':
    return Object.assign({}, state, { isCreating: false, errCreating: null, createStep: 0, selected: record })
  case 'RECEIVE_RECORDS':
    return Object.assign({}, state, { data, isFetching: false, errFetching: null })
  case 'RECEIVE_SELECTED':
    return Object.assign({}, state, { isFetching: false, errFetching: null, selected: record })
  case 'REQUEST_CREATE_RECORD':
    return Object.assign({}, state, { isCreating: true, errCreating: null })
  case 'ERROR_CREATE_RECORD':
    return Object.assign({}, state, { isCreating: false, errCreating: err })
  case 'REQUEST_EDIT_RECORD':
    return Object.assign({}, state, { isEditing: true, errEditing: null })
  case 'ERROR_EDIT_RECORD':
    return Object.assign({}, state, { isEditing: false, errEditing: err })
  case 'RECEIVE_EDIT_RECORD':
    return Object.assign({}, state, { isEditing: false, errEditing: null, viewStep: 0, opened: {} })
  case 'REQUEST_DELETE_RECORD':
    return Object.assign({}, state, { isDeleting: true, errDeleting: null })
  case 'ERROR_DELETE_RECORD':
    return Object.assign({}, state, { isDeleting: false, errDeleting: err })
  case 'CLEAR_ERRORS':
    let errors = {}
    if (state.errFetching !== undefined)
      errors = { errFetching: null, errCreating: null, errDeleting: null, errGenerating: null, errPrinting: null }
    if (state.errFetchingNumPages) errors.errFetchingNumPages = null
    if (state.errEditing) errors.errEditing = null
    if (state.errGenerating) errors.errGenerating = null
    if (state.errPrinting) errors.errPrinting = null
    return Object.assign({}, state, errors)
  case 'REQUEST':
    return Object.assign({}, state, { isFetching: true, errFetching: null })
  case 'REQUEST_PAGE':
    return Object.assign({}, state, { isFetchingPage: true, errFetching: null })
  case 'OPEN':
    return Object.assign({}, state, { opened: record, viewStep: 1 })
  case 'SELECT':
    const { selected } = state
    const index = selected.indexOf(recordId)

    if (isSelected) {
      if (index === -1) {
        selected.push(recordId)
      }
    } else {
      if (index > -1) {
        selected.splice(index, 1)
      }
    }
    return Object.assign({}, state, { selected: [...selected] })
  case 'CLEAR_ALL':
    if ('tabs' in state) {
      let tabs = {}
      Object.keys(state.tabs).forEach(key => {
        const t = Object.assign({}, state.tabs[key], { data: {} })
        tabs[key] = t
      })
      return Object.assign({}, state, { tabs, selected: [] })
    }
    return Object.assign({}, state, { data: {}, selected: [] })
  case 'REQUEST_NUM_PAGES':
    return Object.assign({}, state, { isFetchingNumPages: true, errFetchingNumPages: null })
  case 'ERROR':
    return Object.assign({}, state, { isFetching: false, errFetching: err })
  case 'ERROR_NUM_PAGES':
    return Object.assign({}, state, { isFetchingNumPages: false, errFetchingNumPages: errNumPages })
  case 'RECEIVE_NUM_ROWS':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      if (!tab) {
        return state
      }
      numPages = tab.perPage < 1 ? 1 : Math.ceil(numRows / tab.perPage)
      const t = Object.assign({}, tab, { numPages, numRows })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs, isFetchingNumPages: false, errFetchingNumPages: null })
    }
    numPages = state.perPage < 1 ? 1 : Math.ceil(numRows / state.perPage)
    return Object.assign({}, state, { isFetchingNumPages: false, errFetchingNumPages: null, numPages, numRows })
  case 'RECEIVE_PAGE':
    if ('tabs' in state) {
      // for bills, using state.tab caused issues with data sometimes loading into the incorrect tab. this uses the data's type to set the tab if available.
      const currentTab = state.isBills && data && data.length && data[0].type ? data[0].type.id - 1 : state.tab
      const tab =  state.tabs[currentTab]
      d = Object.assign({}, tab ? tab.data : null, { [page]: data })
      const t = Object.assign({}, tab, { page, data: d })
      const tabs = Object.assign({}, state.tabs, { [currentTab]: t })
      return Object.assign({}, state, { tabs, isFetching: false, isFetchingPage: false, errorFetching: null })
    }
    d = Object.assign({}, state.data, { [page]: data })
    return Object.assign({}, state, { isFetching: false, isFetchingPage: false, errorFetching: null, page, data: d })
  case 'RECEIVE_REFRESHED_PAGE':
    const tab = state.tabs[refreshedTab]
    d = Object.assign({}, tab ? tab.data : null, { [page]: data })
    let t = Object.assign({}, tab, { data: d })
    let tabs = Object.assign({}, state.tabs, { [refreshedTab]: t })
    return Object.assign({}, state, { tabs, isFetching: false, isFetchingPage: false, errorFetching: null })
  case 'RECEIVE_RECORD':
    return Object.assign({}, state, { isFetching: false, errorFetching: null, opened: record })
  case 'SET_PER_PAGE':
    if ('tabs' in state) {
      const tab = state.tabs[state.tab]
      const t = Object.assign({}, tab, { perPage })
      const tabs = Object.assign({}, state.tabs, { [state.tab]: t })
      return Object.assign({}, state, { tabs })
    }
    return Object.assign({}, state, { perPage })
  default:
    return state
  }
}

export const commonReducer = (id, state, action) => {
  const { type } = action
  const typeParts = type.split(':')
  const typeId = typeParts.pop()
  if (typeId !== id) {
    return state
  }

  let commonType = ''
  typeParts.forEach(part => {
    commonType += part + ':'
  })

  if (commonType !== '') {
    action.type = commonType.substr(0, commonType.length - 1)
  } else {
    return state
  }

  return reducer(state, action)
}
