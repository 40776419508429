import { combineReducers } from 'redux'
import address from './addressReducer'
import auth from './authReducer'
import batchJob from './batchJobReducer'
import batchJobType from './batchJobTypeReducer'
import batchJobStatus from './batchJobStatusReducer'
import batchPrintJob from './batchPrintJobReducer'
import billBatchPrintOrderOption from './billBatchPrintOrderOptionReducer'
import billingDetails from './billingDetailsReducer'
import bills from './billsReducer'
import billPaymentTypes from './billPaymentTypesReducer'
import billServiceAtOption from './billServiceAtOptionReducer'
import billStatus from './billStatusReducer'
import billTerms from './billTermsReducer'
import buildings from './buildingsReducer'
import charges from './chargesReducer'
import chargeCodes from './chargeCodesReducer'
import chargeCodeLevels from './chargeCodeLevelsReducer'
import chargeCodeTypes from './chargeCodeTypesReducer'
import contact from './contactReducer'
import customerRateCodes from './customerRateCodesReducer'
import customerChargeCodes from './customerChargeCodesReducer'
import hvacRuntime from './hvacRuntimeReducer'
import invoiceDetails from './invoiceDetailsReducer'
import managements from './managementsReducer'
import meters from './metersReducer'
import meterGroups from './meterGroupsReducer'
import meterTypes from './meterTypesReducer'
import meterUnits from './meterUnitsReducer'
import noReads from './noReadsReducer'
import onlinePaymentTypes from './onlinePaymentTypesReducer'
import payments from './paymentsReducer'
import paymentTypes from './paymentTypesReducer'
import readings from './readingsReducer'
import rates from './ratesReducer'
import rateCodes from './rateCodesReducer'
import rateCodeTypes from './rateCodeTypesReducer'
import readingMethods from './readingMethodsReducer'
import recipients from './recipientsReducer'
import tenants from './tenantsReducer'
import tenantAccountTypes from './tenantAccountTypesReducer'
import tenantDashboard from './tenantDashboardReducer'
import tenantRemitTypes from './tenantRemitTypesReducer'
import units from './unitsReducer'
import unitGroups from './unitGroupsReducer'
import utilityCompanies from './utilityCompaniesReducer'
import utilityTypes from './utilityTypesReducer'
import user from './userReducer'
import userAccounts from './userAccountsReducer'
import userAccountManagements from './userAccountManagementsReducer'
import userAccountManagementUsers from './userAccountManagementUsersReducer'
import userAccountStatuses from './userAccountStatusesReducer'
import userAccountTenantUsers from './userAccountTenantUsersReducer'
import userAccountTypes from './userAccountTypesReducer'
import organizations from './organizationsReducer'
import processingFeePayees from './processingFeePayeesReducer'
import billLineDetailReportColumn from './billLineDetailReportColumnReducer'
import billLineDetailReportData from './billLineDetailReportDataReducer'
import tenantEstimatedBilling from './tenantEstimatedBillingReducer'

const appReducer = combineReducers({
  address,
  auth,
  batchJob,
  batchJobType,
  batchJobStatus,
  batchPrintJob,
  billBatchPrintOrderOption,
  billingDetails,
  bills,
  billLineDetailReportColumn,
  billLineDetailReportData,
  billPaymentTypes,
  billServiceAtOption,
  billStatus,
  billTerms,
  buildings,
  charges,
  chargeCodes,
  chargeCodeLevels,
  chargeCodeTypes,
  contact,
  customerRateCodes,
  customerChargeCodes,
  hvacRuntime,
  invoiceDetails,
  managements,
  meters,
  meterGroups,
  meterTypes,
  meterUnits,
  noReads,
  onlinePaymentTypes,
  payments,
  paymentTypes,
  rates,
  rateCodes,
  rateCodeTypes,
  readings,
  readingMethods,
  recipients,
  tenants,
  tenantAccountTypes,
  tenantDashboard,
  tenantRemitTypes,
  units,
  unitGroups,
  utilityCompanies,
  utilityTypes,
  user,
  userAccounts,
  userAccountManagements,
  userAccountManagementUsers,
  userAccountStatuses,
  userAccountTenantUsers,
  userAccountTypes,
  organizations,
  processingFeePayees,
  tenantEstimatedBilling,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') state = undefined
  return appReducer(state, action)
}

export default rootReducer
