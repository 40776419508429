import { commonReducer } from './commonReducer'
import { grid, pagination } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    default:
      return commonReducer('PROCESSING_FEE_PAYEES', state, action)
  }
}
