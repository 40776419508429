import * as request from 'request-promise-native'
import * as XLSX from 'xlsx/xlsx.mjs'
import { getCommonActions } from './commonActions'
import config from '../config'

export const hvacRuntime = () =>
  getCommonActions('HVAC_RUNTIME', {
    mapState: s => s.hvacRuntime,
    endpoint: 'billing/hvac-runtime',
    searchFields: [],
    labelFields: ['id'],
  })

export const REQUEST_UPLOAD_HVAC_RUNTIME = 'REQUEST_UPLOAD_HVAC_RUNTIME'
export const RECEIVE_UPLOAD_HVAC_RUNTIME = 'RECEIVE_UPLOAD_HVAC_RUNTIME'
export const ERROR_UPLOAD_HVAC_RUNTIME = 'ERROR_UPLOAD_HVAC_RUNTIME'

const requestUploadHvacRuntime = () => {
  return {
    type: REQUEST_UPLOAD_HVAC_RUNTIME,
  }
}

const receiveUploadHvacRuntime = () => {
  return {
    type: RECEIVE_UPLOAD_HVAC_RUNTIME
  }
}

const errorUploadHvacRuntime = err => {
  return {
    type: ERROR_UPLOAD_HVAC_RUNTIME,
    err,
  }
}

export const uploadHvacRuntime = (file, buildingId, date) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    dispatch(requestUploadHvacRuntime())

    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data)
    const sheet = workbook.SheetNames[0]
    let _hvacRuntime = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
    if (_hvacRuntime && _hvacRuntime.length) {
      _hvacRuntime = _hvacRuntime.map(r => ({
        sum: r.Sum,
        unitName: r['Unit name']
      }))
      date.setHours(12, 0, 0, 0)
      const options = {
        method: 'POST',
        uri: `${config.baseUrl}/billing/hvac-runtime/import`,
        headers: {
          auth: JSON.stringify({ token: loginToken }),
        },
        body: {
          hvacRuntime: _hvacRuntime,
          buildingId,
          date,
        },
        json: true,
      }
      const req = await request(options)
      if (!req) return dispatch(errorUploadHvacRuntime('There was a problem uploading the HVAC Runtime values!'))
      if (req.err) return dispatch(errorUploadHvacRuntime(req.err.toString()))
      dispatch(receiveUploadHvacRuntime())
      dispatch(hvacRuntime().getNumPages())
      dispatch(hvacRuntime().refresh())
    } else {
      return dispatch(errorUploadHvacRuntime('There was a problem uploading the HVAC Runtime values!'))
    }
  }
}
