import * as request from 'request-promise-native'
import validator from 'validator'
import { requestLogin, receiveLogin, logout } from './authActions'
import { receiveSelectUserAccountManagement } from './userAccountManagementsActions'
import config from '../config'

export const REQUEST_USER = 'REQUEST_USER'
export const RECEIVE_USER = 'RECEIVE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const REQUEST_REDIRECT_USER = 'REQUEST_REDIRECT_USER'
export const REDIRECT_USER = 'REDIRECT_USER'
export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER'
export const RECEIVE_CREATE_USER = 'RECEIVE_CREATE_USER'
export const ERROR_CREATE_USER = 'ERROR_CREATE_USER'
export const REQUEST_REGISTER_USER = 'REQUEST_REGISTER_USER'
export const RECEIVE_REGISTER_USER = 'RECEIVE_REGISTER_USER'
export const ERROR_REGISTER_USER = 'ERROR_REGISTER_USER'

export const requestUser = loginToken => {
  return {
    type: REQUEST_USER,
    loginToken,
  }
}

export const receiveUser = user => {
  return {
    type: RECEIVE_USER,
    user,
  }
}

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  }
}

export const requestRedirectUser = () => {
  return {
    type: REQUEST_REDIRECT_USER,
  }
}

export const redirectUser = (typeId, history) => {
  switch (typeId) {
  case 1:
    history.push('/admin/dashboard')
    break
  case 2:
    history.push('/mgmt/dashboard')
    break
  case 3:
    history.push('/tenant/dashboard')
    break
  case -1:
    break
  default:
    history.push('/')
    break
  }

  return {
    type: REDIRECT_USER,
  }
}

export const requestCreateUser = () => {
  return {
    type: REQUEST_CREATE_USER,
  }
}

export const receiveCreateUser = user => {
  return {
    type: RECEIVE_CREATE_USER,
    user,
  }
}

const errorCreateUser = err => {
  return {
    type: ERROR_CREATE_USER,
    err,
  }
}

export const requestRegisterUser = () => {
  return {
    type: REQUEST_REGISTER_USER,
  }
}

export const receiveRegisterUser = user => {
  return {
    type: RECEIVE_REGISTER_USER,
    user,
  }
}

const errorRegisterUser = err => {
  return {
    type: ERROR_REGISTER_USER,
    err,
  }
}

export const getUser = () => {
  return async dispatch => {
    const loginToken = localStorage.getItem('loginToken')

    dispatch(requestUser(loginToken))

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/user/login/${loginToken}`,
      json: true,
    }

    const req = await request(options)

    const { err, res: user } = req

    if (err) {
      logout()
    } else {
      dispatch(receiveUser(user))
      if (user.typeId === 2) {
        dispatch(receiveSelectUserAccountManagement(user.managementId))
      }
    }
  }
}

export const register = fields => {
  return async dispatch => {
    dispatch(requestRegisterUser())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/register`,
      body: fields,
      json: true,
    }

    const req = await request(options)
    const { err, res: user } = req

    if (err) {
      dispatch(errorRegisterUser(err.toString()))
    } else {
      dispatch(receiveRegisterUser(user))
    }
  }
}

export const createUser = (email, password, confirmPassword, accountNumber) => {
  return async dispatch => {
    dispatch(requestCreateUser())

    if (!accountNumber || !accountNumber.length) return dispatch(errorCreateUser('Account number is a required field!'))

    if (!email || !email.length) return dispatch(errorCreateUser('Email is a required field!'))
    if (!validator.isEmail(email)) return dispatch(errorCreateUser('Email is invalid!'))

    if (!password || !password.length) return dispatch(errorCreateUser('Password is a required field!'))
    if (password.length < 8) return dispatch(errorCreateUser('Password must be 8 characters or longer!'))

    if (password !== confirmPassword) return dispatch(errorCreateUser('Passwords don\'t match!'))

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/user`,
      body: {
        email,
        password,
        account: {
          number: accountNumber,
        },
      },
      json: true,
    }

    const req = await request(options)

    const { err, res: user } = req

    if (err) {
      dispatch(errorCreateUser(err.toString()))
    } else {
      const { loginToken } = user
      dispatch(receiveCreateUser(user))
      dispatch(requestLogin())
      dispatch(receiveLogin(loginToken))
    }
  }
}
