import { numRows } from '../utils/numRows'
import * as _request from 'request-promise-native'
import config from '../config'
import { forAPI } from '../utils/filterOptions'

export const actions = {
  setSelectedOption: {
    action: id => setSelectedOption(id),
  },
  clearFilters: {
    action: id => clearFilters(id),
  },
  addFilter: {
    action: id => addFilter(id),
  },
  updateFilter: {
    action: id => updateFilter(id),
  },
  removeFilter: {
    action: id => removeFilter(id),
  },
  setCreateStep: {
    action: id => setCreateStep(id),
  },
  setViewStep: {
    action: id => setViewStep(id),
  },
  receiveRecords: {
    action: id => receiveRecords(id),
  },
  receiveSelected: {
    action: id => receiveSelected(id),
  },
  receiveCreateRecord: {
    action: id => receiveCreateRecord(id),
  },
  requestCreateRecord: {
    action: id => requestCreateRecord(id),
  },
  errorCreateRecord: {
    action: id => errorCreateRecord(id),
  },
  requestEditRecord: {
    action: id => requestEditRecord(id),
  },
  receiveEditRecord: {
    action: id => receiveEditRecord(id),
  },
  errorEditRecord: {
    action: id => errorEditRecord(id),
  },
  requestDeleteRecord: {
    action: id => requestDeleteRecord(id),
  },
  receiveDeleteRecord: {
    action: id => receiveDeleteRecord(id),
  },
  errorDeleteRecord: {
    action: id => errorDeleteRecord(id),
  },
  deleteRecord: {
    action: (id, params) => deleteRecord(id, params),
  },
  editRecord: {
    action: (id, params) => editRecord(id, params),
  },
  createRecord: {
    action: (id, params) => createRecord(id, params),
  },
  request: {
    action: id => request(id),
  },
  refresh: {
    action: (id, params) => refresh(id, params),
  },
  refreshPage: {
    action: (id, params) => refreshPage(id, params),
  },
  clearErrors: {
    action: id => clearErrors(id),
  },
  clearAll: {
    action: id => clearAll(id),
  },
  requestNumPages: {
    action: id => requestNumPages(id),
  },
  error: {
    action: id => error(id),
  },
  errorNumPages: {
    action: id => errorNumPages(id),
  },
  receiveNumRows: {
    action: id => receiveNumRows(id),
  },
  getNumPages: {
    action: (id, params) => getNumPages(id, params),
  },
  receivePage: {
    action: id => receivePage(id),
  },
  selectPage: {
    action: (id, params) => selectPage(id, params),
  },
  loadOptions: {
    action: (id, params) => loadOptions(id, params),
  },
  getAllRecords: {
    action: (id, params) => getAllRecords(id, params),
  },
  getRecord: {
    action: (id, params) => getRecord(id, params),
  },
  select: {
    action: (id, params) => select(id, params),
  },
  open: {
    action: (id, params) => open(id, params),
  },
  setPerPage: {
    action: id => setPerPage(id),
  },
}

const setSelectedOption = id => option => {
  return {
    type: 'SET_SELECTED_OPTION' + id,
    option,
  }
}

const addFilter = id => filter => {
  return {
    type: 'ADD_FILTER' + id,
    filter,
  }
}

const updateFilter = id => filter => {
  return {
    type: 'UPDATE_FILTER' + id,
    filter,
  }
}

const removeFilter = id => filter => {
  return {
    type: 'REMOVE_FILTER' + id,
    filter,
  }
}

const clearFilters = id => () => {
  return {
    type: 'CLEAR_FILTERS' + id,
  }
}

const setCreateStep = id => step => {
  return {
    type: 'SET_CREATE_STEP' + id,
    step,
  }
}

const setViewStep = id => step => {
  return {
    type: 'SET_VIEW_STEP' + id,
    step,
  }
}

const receiveCreateRecord = id => record => {
  return {
    type: 'RECEIVE_CREATE_RECORD' + id,
    record,
  }
}

const requestCreateRecord = id => () => {
  return {
    type: 'REQUEST_CREATE_RECORD' + id,
  }
}

const errorCreateRecord = id => err => {
  return {
    type: 'ERROR_CREATE_RECORD' + id,
    err,
  }
}

const receiveRecords = id => data => {
  return {
    type: 'RECEIVE_RECORDS' + id,
    data,
  }
}

const receiveSelected = id => record => {
  return {
    type: 'RECEIVE_SELECTED' + id,
    record,
  }
}

const requestEditRecord = id => () => {
  return {
    type: 'REQUEST_EDIT_RECORD' + id,
  }
}

const receiveEditRecord = id => record => {
  return {
    type: 'RECEIVE_EDIT_RECORD' + id,
    record,
  }
}

const errorEditRecord = id => err => {
  return {
    type: 'ERROR_EDIT_RECORD' + id,
    err,
  }
}

const requestDeleteRecord = id => () => {
  return {
    type: 'REQUEST_DELETE_RECORD' + id,
  }
}

const receiveDeleteRecord = id => () => {
  return {
    type: 'RECEIVE_DELETE_RECORD' + id,
  }
}

const errorDeleteRecord = id => err => {
  return {
    type: 'ERROR_DELETE_RECORD' + id,
    err,
  }
}

const request = id => () => {
  return {
    type: 'REQUEST' + id,
  }
}

const requestPage = id => () => {
  return {
    type: 'REQUEST_PAGE' + id,
  }
}

const requestNumPages = id => () => {
  return {
    type: 'REQUEST_NUM_PAGES' + id,
  }
}

const error = id => err => {
  return {
    type: 'ERROR' + id,
    err,
  }
}

const errorNumPages = id => errNumPages => {
  return {
    type: 'ERROR_NUM_PAGES' + id,
    errNumPages,
  }
}

const receiveNumRows = id => numRows => {
  return {
    type: 'RECEIVE_NUM_ROWS' + id,
    numRows,
  }
}

const clearErrors = id => () => {
  return {
    type: 'CLEAR_ERRORS' + id,
  }
}

const clearAll = id => () => {
  return {
    type: 'CLEAR_ALL' + id,
  }
}

const select = id => (recordId, isSelected) => {
  return {
    type: 'SELECT' + id,
    recordId,
    isSelected,
  }
}

const setPerPage = id => perPage => {
  return async dispatch => dispatch({
    type: 'SET_PER_PAGE' + id,
    perPage,
  })
}

const loadOptions = (id, params) => (args = [], additionalOptions = []) => async (search, loadedOptions, { page }) => {
  const { searchFields, endpoint, labelFields, defaultLoadOptions } = params
  const loginToken = localStorage.getItem('loginToken')
  const perPage = 25

  const fields = args.fields ? typeof args.fields === 'string' ? JSON.parse(decodeURIComponent(args.fields)) : args.fields : {}

  let hasDefaultFields = false
  if (defaultLoadOptions && defaultLoadOptions.fields) {
    Object.keys(defaultLoadOptions.fields).forEach(field => {
      fields[field] = defaultLoadOptions.fields[field]
      hasDefaultFields = true
    })
  }

  const _searchFields = {}
  searchFields.forEach(field => {
    _searchFields[field] = {
      type: 'LIKE',
      value: '%' + search + '%',
    }
  })

  let additionalArgs = ''
  Object.keys(args).forEach(key => {
    if (key !== 'fields' && !hasDefaultFields) {
      let value = args[key]
      if (typeof value === 'object') {
        value = encodeURIComponent(JSON.stringify(value))
      }
      additionalArgs += `&${key}=${value}`
    }
  })

  let req = await numRows(endpoint, loginToken, fields, _searchFields)
  const { res: num, err } = req

  if ((num || num === 0) && !err) {
    const numPages = Math.ceil(num / perPage)

    let searchUri = ''
    if (_searchFields !== {}) {
      searchUri = `&search=${encodeURIComponent(JSON.stringify(_searchFields))}`
    }
    let fieldsUri = ''
    if (fields !== {}) {
      fieldsUri = `&fields=${encodeURIComponent(JSON.stringify(fields))}`
    }

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/${endpoint}?page=${page}&perPage=${perPage}${searchUri}${fieldsUri}` + additionalArgs,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    req = await _request(options)

    const { res: rows, err } = req

    if (rows && !err) {
      const hasMore = page < numPages

      let options = rows.map(r => {
        let label = ''
        labelFields.forEach(field => {
          if (r[field]) {
            label += `${r[field]} - `
          }
        })
        if (label !== '') {
          label = label.substr(0, label.length - 3)
        }
        return {
          value: r.id,
          label,
        }
      })

      additionalOptions.length && options.unshift(...additionalOptions)

      return {
        options,
        hasMore,
        additional: {
          page: page + 1
        }
      }
    }
  }
}

const refresh = (id, params) => args => {
  return async (dispatch, getState) => {
    const { mapState } = params
    const state = mapState(getState())
    const page = state.page ? state.page : state.tabs ? state.tabs[state.tab] ? state.tabs[state.tab].page : 1 : 1
    let currentPage

    // Can this be removed for smoother refresh?
    //dispatch(clearAll(id)())

    if ('tabs' in state) {
      let numPages = state.tabs[state.tab] ? state.tabs[state.tab].numPages : 1
      let tabPage = state.tabs[state.tab] ? state.tabs[state.tab].page : 1
      currentPage = numPages ? tabPage > numPages ? numPages : tabPage : 1
      dispatch(getNumPages(id, params)(args))
      dispatch(selectPage(id, params)(currentPage, args, true))
    } else {
      currentPage = state.numPages ? page > state.numPages ? state.numPages : page : page
      dispatch(getNumPages(id, params)(args))
      dispatch(selectPage(id, params)(currentPage, args, true))
    }
  }
}

const getNumPages = (id, params) => fields => {
  return async (dispatch, getState) => {
    const { endpoint, mapState } = params
    const { loginToken } = getState().auth
    const state = mapState(getState())

    dispatch(requestNumPages(id)())

    let filters = {}
    if ('tabs' in state) {
      if (state.tabs[state.tab] && 'filters' in state.tabs[state.tab]) {
        filters = state.tabs[state.tab].filters
      }
    } else if ('filters' in state) {
      filters = state.filters
    }

    if (filters !== {} && filters !== null && filters !== undefined) {
      fields = { ...fields, ...forAPI(filters) }
    }

    const req = await numRows(endpoint, loginToken, fields)

    if (!req)
      return dispatch(errorNumPages(id)(`There was a problem getting the number or rows for endpoint '${endpoint}'!`))

    const { err, res } = req
    if (err) return dispatch(errorNumPages(id)(err.toString()))
    dispatch(receiveNumRows(id)(res))
  }
}

const receivePage = id => (page, data) => {
  return {
    type: 'RECEIVE_PAGE' + id,
    page,
    data,
  }
}

const receiveRefreshedPage = id => (page, data, tab) => {
  return {
    type: 'RECEIVE_REFRESHED_PAGE' + id,
    page,
    data,
    tab
  }
}

const receiveRecord = id => record => {
  return {
    type: 'RECEIVE_RECORD' + id,
    record,
  }
}

const getAllRecords = (id, params) => (args = [], useFilters = true) => {
  return async (dispatch, getState) => {
    const { endpoint, mapState } = params
    const { loginToken } = getState().auth
    const state = mapState(getState())

    let filters = {}
    if (useFilters) {
      if ('tabs' in state) {
        if ('filters' in state.tabs[state.tab]) {
          filters = state.tabs[state.tab].filters
        }
      } else if ('filters' in state) {
        filters = state.filters
      }
    }

    if (filters !== {} && filters !== null && filters !== undefined) {
      if ('fields' in args) {
        args.fields = { ...args.fields, ...forAPI(filters) }
      } else {
        args.fields = forAPI(filters)
      }
    }

    let additionalArgs = ''
    let i = 0
    Object.keys(args).forEach(key => {
      if (i === 0) {
        additionalArgs += `?${key}=${encodeURIComponent(JSON.stringify(args[key]))}`
      } else {
        additionalArgs += `&${key}=${encodeURIComponent(JSON.stringify(args[key]))}`
      }
      i++
    })

    dispatch(request(id)())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/${endpoint}` + additionalArgs,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await _request(options)
    if (!req)
      return dispatch(error(id)(`There was a problem loading records with endpoint ${config.baseUrl}/${endpoint}!`))

    const { err, res: data } = req
    if (err) return dispatch(error(id)(err.toString()))
    dispatch(receiveRecords(id)(data))
  }
}

const selectPage = (id, params) => (page, args = [], force = false) => {
  return async (dispatch, getState) => {
    const { mapState, endpoint } = params
    const { loginToken } = getState().auth
    const state = mapState(getState())
    let { perPage } = state

    if ('tabs' in state) {
      if (!state.tabs[state.tab]) {
        return
      }
      if ((state.tabs[state.tab].numRows/Number(state.tabs[state.tab].perPage)) <= 1 || Number(state.tabs[state.tab].perPage) === -1 ) {
        page = 1
      }
      perPage = state.tabs[state.tab].perPage

      if (page in state.tabs[state.tab].data && !force) {
        dispatch(receivePage(id)(page, state.tabs[state.tab].data[page]))
        return
      }
    } else {
      if (page in state.data && !force) {
        dispatch(receivePage(id)(page, state.data[page]))
        return
      }
    }

    let filters = {}
    if ('tabs' in state) {
      if ('filters' in state.tabs[state.tab]) {
        filters = state.tabs[state.tab].filters
      }
    } else if ('filters' in state) {
      filters = state.filters
    }

    if (filters !== {} && filters !== null && filters !== undefined) {
      if ('fields' in args) {
        args.fields = { ...args.fields, ...forAPI(filters) }
      } else {
        args.fields = forAPI(filters)
      }
    }

    let additionalArgs = ''
    Object.keys(args).forEach(key => {
      additionalArgs += `&${key}=${encodeURIComponent(JSON.stringify(args[key]))}`
    })

    dispatch(requestPage(id)())
    dispatch(request(id)())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/${endpoint}?page=${page}&perPage=${perPage}` + additionalArgs,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await _request(options)
    if (!req)
      return dispatch(
        error(id)(
          `There was a problem loading page number ${page} of data from endpoint ${config.baseUrl}/${endpoint}!`,
        ),
      )

    const { err, res: data } = req
    if (err) return dispatch(error(id)(err.toString()))
    dispatch(receivePage(id)(page, data))
  }
}

const refreshPage = (id, params) => (page, args = [], tab) => {
  return async (dispatch, getState) => {
    const { mapState, endpoint } = params
    const { loginToken } = getState().auth
    const state = mapState(getState())
    let { perPage } = state

    if (tab === null || tab === undefined) {
      return
    }

    if ('tabs' in state) {
      if (!state.tabs[state.tab]) {
        return
      }
      perPage = state.tabs[state.tab].perPage
    }

    let filters = {}
    if ('tabs' in state) {
      if ('filters' in state.tabs[state.tab]) {
        filters = state.tabs[state.tab].filters
      }
    } else if ('filters' in state) {
      filters = state.filters
    }

    if (filters !== {} && filters !== null && filters !== undefined) {
      if ('fields' in args) {
        args.fields = { ...args.fields, ...forAPI(filters) }
      } else {
        args.fields = forAPI(filters)
      }
    }

    let additionalArgs = ''
    Object.keys(args).forEach(key => {
      additionalArgs += `&${key}=${encodeURIComponent(JSON.stringify(args[key]))}`
    })

    dispatch(requestPage(id)())
    dispatch(request(id)())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/${endpoint}?page=${page}&perPage=${perPage}` + additionalArgs,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await _request(options)
    if (!req)
      return dispatch(
        error(id)(
          `There was a problem loading page number ${page} of data from endpoint ${config.baseUrl}/${endpoint}!`,
        ),
      )

    const { err, res: data } = req
    if (err) return dispatch(error(id)(err.toString()))
    if(state.tabs[tab].perPage !== mapState(getState()).tabs[tab].perPage) {
      dispatch(refresh(id, params)())
    } else {
      dispatch(receiveRefreshedPage(id)(page, data, tab))
    }
  }
}

const open = (id, params) => recordId => {
  return async dispatch => {
    dispatch(setViewStep(id)(1))
    dispatch(getRecord(id, params)(recordId))
  }
}

const getRecord = (id, params) => recordId => {
  return async (dispatch, getState) => {
    const { endpoint } = params
    const { loginToken } = getState().auth

    dispatch(request(id)())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/${endpoint}/${recordId}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await _request(options)
    const { err, res } = req

    if (err) {
      dispatch(error(id)(err.toString()))
    } else {
      dispatch(receiveRecord(id)(res[0]))
    }
  }
}

const createRecord = (id, params) => (record, _refresh = true) => {
  return async (dispatch, getState) => {
    const { endpoint } = params
    const { loginToken } = getState().auth

    dispatch(requestCreateRecord(id)())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/${endpoint}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: record,
      json: true,
    }

    const req = await _request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorCreateRecord(id)(err.toString()))
    } else {
      dispatch(receiveCreateRecord(id)(res))
      if (_refresh) dispatch(refresh(id, params)())
    }
  }
}

const editRecord = (id, params) => (record, _refresh = true) => {
  return async (dispatch, getState) => {
    const { endpoint } = params
    const { loginToken } = getState().auth

    dispatch(requestEditRecord(id)())

    const options = {
      method: 'PATCH',
      uri: `${config.baseUrl}/${endpoint}/${record.id}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: record,
      json: true,
    }

    const req = await _request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorEditRecord(id)(err.toString()))
    } else {
      dispatch(receiveEditRecord(id)(res))
      if (_refresh) dispatch(refresh(id, params)())
    }
  }
}

const deleteRecord = (id, params) => (recordId, fields = null, _refresh = true) => {
  return async (dispatch, getState) => {
    const { endpoint } = params
    const { loginToken } = getState().auth

    dispatch(requestDeleteRecord(id)())

    const options = {
      method: 'DELETE',
      uri: `${config.baseUrl}/${endpoint}${fields ? `?fields=${fields}` : `/${recordId}`}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await _request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorDeleteRecord(id)(err.toString()))
    } else {
      dispatch(receiveDeleteRecord(id)(res))
      if (_refresh) dispatch(refresh(id, params)())
    }
  }
}

export const getCommonActions = (id, params = null) => {
  let common = {}
  Object.keys(actions).forEach(key => {
    common[key] = actions[key].action(':' + id, params)
  })
  return common
}
