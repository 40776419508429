import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import {
  REQUEST_CONNECT_ACCOUNT,
  RECEIVE_CONNECT_ACCOUNT,
  CLEAR_CONNECT_ACCOUNT,
  NO_CONNECT_ACCOUNT,
  ERROR_CONNECT_ACCOUNT,
  CLEAR_ERROR_CONNECT_ACCOUNT,
  REQUEST_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
  RECEIVE_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
  ERROR_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT,
  REQUEST_DOWNLOAD_HVAC_LOOP_BILLS,
  RECEIVE_DOWNLOAD_HVAC_LOOP_BILLS,
  ERROR_DOWNLOAD_HVAC_LOOP_BILLS,
  REQUEST_DOWNLOAD_AGING_REPORT,
  RECEIVE_DOWNLOAD_AGING_REPORT,
  ERROR_DOWNLOAD_AGING_REPORT,
} from '../actions/managementsActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  hasConnectAccount: false,
  errorConnectAccount: null,
  connectAccount: {
    settings: {
      payouts: {
        schedule: {
          delay_days: 0,
          interval: '',
          weekly_anchor: '',
          monthly_anchor: '',
        },
      },
    },
    company: {
      tax_id_provided: false,
    },
    external_accounts: {
      data: [
        {
          last4: '',
          routing_number: '',
        },
      ],
    },
  },
  isDownloadingFormalManagementSummariesReport: false,
  errDownloadingFormalManagementSummariesReport: null,
  isDownloadingAgingReport: false,
  errDownloadingAgingReport: null,
}

export default (state = initialState, action) => {
  const { type, account, err } = action

  switch (type) {
  case CLEAR_CONNECT_ACCOUNT:
    return { ...state, connectAccount: account }
  case REQUEST_CONNECT_ACCOUNT:
    return { ...state, isFetching: true, errSelected: null }
  case NO_CONNECT_ACCOUNT:
    return { ...state, isFetching: false, hasConnectAccount: false }
  case RECEIVE_CONNECT_ACCOUNT:
    return { ...state, connectAccount: account, hasConnectAccount: true, isFetching: false }
  case ERROR_CONNECT_ACCOUNT:
    return { ...state, errorConnectAccount: err }
  case CLEAR_ERROR_CONNECT_ACCOUNT:
    return { ...state, errorConnectAccount: null }
  case REQUEST_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT:
    return Object.assign({}, state, {
      isDownloadingFormalManagementSummariesReport: true,
      errDownloadingFormalManagementSummariesReport: null,
    })
  case RECEIVE_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT:
    return Object.assign({}, state, {
      isDownloadingFormalManagementSummariesReport: false,
      errDownloadingFormalManagementSummariesReport: null,
    })
  case ERROR_DOWNLOAD_FORMAL_MANAGEMENT_SUMMARIES_REPORT:
    return Object.assign({}, state, {
      isDownloadingFormalManagementSummariesReport: false,
      errDownloadingFormalManagementSummariesReport: err,
    })
  case REQUEST_DOWNLOAD_HVAC_LOOP_BILLS:
    return Object.assign({}, state, {
      isDownloadingHvacLoopBills: true,
      errDownloadingHvacLoopBills: null,
    })
  case RECEIVE_DOWNLOAD_HVAC_LOOP_BILLS:
    return Object.assign({}, state, {
      isDownloadingHvacLoopBills: false,
      errDownloadingHvacLoopBills: null,
    })
  case ERROR_DOWNLOAD_HVAC_LOOP_BILLS:
    return Object.assign({}, state, {
      isDownloadingHvacLoopBills: false,
      errDownloadingHvacLoopBills: err,
    })
  case REQUEST_DOWNLOAD_AGING_REPORT:
    return Object.assign({}, state, {
      isDownloadingAgingReport: true,
      errDownloadingAgingReport: null,
    })
  case RECEIVE_DOWNLOAD_AGING_REPORT:
    return Object.assign({}, state, {
      isDownloadingAgingReport: false,
      errDownloadingAgingReport: null,
    })
  case ERROR_DOWNLOAD_AGING_REPORT:
    return Object.assign({}, state, {
      isDownloadingAgingReport: false,
      errDownloadingAgingReport: err,
    })
  default:
    return commonReducer('MANAGEMENTS', state, action)
  }
}

/*import {
  REQUEST_MANAGEMENT,
  RECEIVE_MANAGEMENT,
  ERROR_MANAGEMENT,
  CLEAR_MANAGEMENT,
  REQUEST_MANAGEMENTS,
  RECEIVE_MANAGEMENTS,
  ERROR_MANAGEMENTS,
  SELECT_PAGE_MANAGEMENTS,
  SET_CREATE_STEP_MANAGEMENT,
  REQUEST_CREATE_MANAGEMENT,
  RECEIVE_CREATE_MANAGEMENT,
  ERROR_CREATE_MANAGEMENT,
  CLEAR_CREATE_MANAGEMENT,
  SET_VIEW_STEP_MANAGEMENT,
  ENABLE_EDIT_MANAGEMENT,
  REQUEST_EDIT_MANAGEMENT,
  RECEIVE_EDIT_MANAGEMENT,
  ERROR_EDIT_MANAGEMENT
} from '../actions/managementsActions'

const initialState = {
  list: [],
  selectedPageList: [],
  page: null,
  perPage: 50,
  numPages: null,
  isFetching: false,
  errFetching: null,
  createStep: 0,
  isCreating: false,
  errCreating: null,
  viewStep: 0,
  selected: {
    id: null,
    number: null,
    name: null,
    addresses: [],
    contacts: []
  },
  isFetchingSelected: false,
  errSelected: null,
  isEditing: false,
  requestEdit: false,
  errEditing: null
}

export default (state = initialState, action) => {
  const { management, managements, page, step, err, type } = action
  let numPages, p, selectedPageList

  switch(type) {
    case REQUEST_MANAGEMENT:
      return { ...state, isFetchingSelected: true, errSelected: null }
      return Object.assign({}, state, { isFetchingSelected: true, errSelected: null })

    case RECEIVE_MANAGEMENT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: null, selected: management })

    case ERROR_MANAGEMENT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: err })

    case CLEAR_MANAGEMENT:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: null, selected: initialState.selected })

    case REQUEST_MANAGEMENTS:
      return Object.assign({}, state, { isFetching: true, errFetching: null })

    case RECEIVE_MANAGEMENTS:
      numPages = Math.ceil(managements.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = managements.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list: managements, isFetching: false, errFetching: null, page: p, numPages, selectedPageList })

    case ERROR_MANAGEMENTS:
      return Object.assign({}, state, { isFetching: false, errFetching: err })

    case SELECT_PAGE_MANAGEMENTS:
      return Object.assign({}, state, { page, selectedPageList: state.list.slice((page-1) * state.perPage, page * state.perPage) })

    case SET_CREATE_STEP_MANAGEMENT:
      return Object.assign({}, state, { createStep: step })

    case REQUEST_CREATE_MANAGEMENT:
      return Object.assign({}, state, { isCreating: true, errCreating: null })

    case RECEIVE_CREATE_MANAGEMENT:
      const list = state.list
      list.push(management)
      numPages = Math.ceil(list.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = list.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list, isCreating: false, errCreating: null, createStep: 0, page: p, numPages, selectedPageList })

    case ERROR_CREATE_MANAGEMENT:
      return Object.assign({}, state, { isCreating: false, errCreating: err })

    case CLEAR_CREATE_MANAGEMENT:
      return Object.assign({}, state, { isCreating: false, errCreating: null })

    case SET_VIEW_STEP_MANAGEMENT:
      return Object.assign({}, state, { viewStep: step })

    case ENABLE_EDIT_MANAGEMENT:
      return Object.assign({}, state, { isEditing: true, errEditing: null })

    case REQUEST_EDIT_MANAGEMENT:
      return Object.assign({}, state, { requestEdit: true, errEditing: null })

    case RECEIVE_EDIT_MANAGEMENT:
      return Object.assign({}, state, { isEditing: false, requestEdit: false, selected: management, errEditing: null })

    case ERROR_EDIT_MANAGEMENT:
      return Object.assign({}, state, { isEditing: false, requestEdit: false, errEditing: err })

    default:
      return state
  }
}*/
