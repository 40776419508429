import config from '../config'
import * as request from 'request-promise-native'

export const REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION'
export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION'
export const REQUEST_EDIT_ORG = 'REQUEST_EDIT_ORG'
export const ERROR_EDIT_ORG = 'ERROR_EDIT_ORG'
export const ERROR_FETCHING = 'ERROR_FETCHING'

export const reqOrganization = () => {
  return {
    type: REQUEST_ORGANIZATION,
  }
}

export const recOrganization = data => {
  return {
    type: RECEIVE_ORGANIZATION,
    data
  }
}

export const reqEditOrg = () => {
  return {
    type: REQUEST_EDIT_ORG,
  }
}

export const errorUpdateOrg = err => {
  return{
    type: ERROR_EDIT_ORG,
    err,
  }
}

export const errFetching = err => {
  return{
    type: ERROR_FETCHING,
    err,
  }
}

export const editOrganization = record => {
  return async (dispatch,getState) => {
    const { loginToken } = getState().auth

    dispatch(reqEditOrg())

    const options = {
      method: 'PATCH',
      uri: `${config.baseUrl}/organization`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: record,
      json: true,
    }

    const req = await request(options)
    const { err, res: data } = req

    if (err) {
      dispatch(errorUpdateOrg(err))
    } else {
      dispatch(recOrganization(data))
    }
  }
}

export const editOrgAddress = record => {
  return async (dispatch, getState) => {
    const { endpoint, address, id } = record
    const { loginToken } = getState().auth

    dispatch(reqEditOrg())

    const options = {
      method: 'PATCH',
      uri: `${config.baseUrl}/${endpoint}/${id}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: address,
      json: true,
    }

    const req = await request(options)

    const { err, res: data } = req
    if (err) {
      dispatch(errorUpdateOrg(err))
    } else {
      dispatch(recOrganization({address:data}))
    }
  }
}

export const getOrganization = () => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(reqOrganization())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/organization`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await request(options)
    const { err, res } = req

    if (!req)
      return dispatch(errFetching(`There was a problem loading organization with endpoint ${config.baseUrl}/organization!`))

    if (err) {
      dispatch(errFetching(err))
    } else {
      dispatch(recOrganization(res))
    }
  }
}
