import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('BUILDINGS', state, action)
  }
}

/*import {
  REQUEST_BUILDING,
  RECEIVE_BUILDING,
  ERROR_BUILDING,
  CLEAR_BUILDING,
  SET_VIEW_STEP_BUILDING,
  REQUEST_BUILDINGS,
  RECEIVE_BUILDINGS,
  ERROR_BUILDINGS,
  SELECT_PAGE_BUILDINGS,
  SET_CREATE_STEP_BUILDING,
  REQUEST_CREATE_BUILDING,
  RECEIVE_CREATE_BUILDING,
  ERROR_CREATE_BUILDING,
  CLEAR_CREATE_BUILDING,
  ENABLE_EDIT_BUILDING,
  REQUEST_EDIT_BUILDING,
  RECEIVE_EDIT_BUILDING,
  ERROR_EDIT_BUILDING
} from '../actions/buildingsActions'

const initialState = {
  list: [],
  selected: {
    id: null,
    number: null,
    name: null,
    address: {},
    contacts: []
  },
  isFetchingSelected: false,
  errSelected: false,
  page: null,
  perPage: 50,
  numPages: null,
  selectedPageList: [],
  createStep: 0,
  viewStep: 0,
  isCreating: false,
  errCreating: null,
  isFetching: false,
  errFetching: null,
  isEditing: false,
  isLoadingEdit: false,
  errEditing: null
}

export default (state = initialState, action) => {
  const { building, buildings, page, step, err, type } = action
  let numPages, p, selectedPageList

  switch(type) {
    case REQUEST_BUILDING:
      return Object.assign({}, state, { isFetchingSelected: true, errSelected: null })

    case RECEIVE_BUILDING:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: null, selected: building })

    case ERROR_BUILDING:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: err })

    case CLEAR_BUILDING:
      return Object.assign({}, state, { isFetchingSelected: false, errSelected: false, selected: initialState.selected })

    case SET_VIEW_STEP_BUILDING:
      return Object.assign({}, state, { viewStep: step })

    case REQUEST_BUILDINGS:
      return Object.assign({}, state, { isFetching: true, errFetching: null })

    case RECEIVE_BUILDINGS:
      numPages = Math.ceil(buildings.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = buildings.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list: buildings, isFetching: false, errFetching: null, page: p, numPages, selectedPageList })

    case ERROR_BUILDINGS:
      return Object.assign({}, state, { isFetching: false, errFetching: err })

    case SET_CREATE_STEP_BUILDING:
      return Object.assign({}, state, { createStep: step })

    case REQUEST_CREATE_BUILDING:
      return Object.assign({}, state, { isCreating: true, errCreating: null })

    case RECEIVE_CREATE_BUILDING:
      const list = state.list
      list.push(building)
      numPages = Math.ceil(list.length / state.perPage)
      p = state.page && state.page <= numPages ? state.page : 1
      selectedPageList = list.slice((p-1) * state.perPage, p * state.perPage)
      return Object.assign({}, state, { list, isCreating: false, errCreating: null, createStep: 0, page: p, numPages, selectedPageList })

    case ERROR_CREATE_BUILDING:
      return Object.assign({}, state, { isCreating: false, errCreating: err })

    case CLEAR_CREATE_BUILDING:
      return Object.assign({}, state, { isCreating: false, errCreating: null })

    case SELECT_PAGE_BUILDINGS:
      return Object.assign({}, state, { page, selectedPageList: state.list.slice((page-1) * state.perPage, page * state.perPage) })

    case ENABLE_EDIT_BUILDING:
      return Object.assign({}, state, { isEditing: true, errEditing: null })

    case REQUEST_EDIT_BUILDING:
      return Object.assign({}, state, { isLoadingEdit: true, errEditing: null })

    case RECEIVE_EDIT_BUILDING:
      return Object.assign({}, state, { isEditing: false, isLoadingEdit: false, selected: building, errEditing: null })

    case ERROR_EDIT_BUILDING:
      return Object.assign({}, state, { isEditing: false, isLoadingEdit: false, errEditing: err })

    default:
      return state
  }
}
*/
