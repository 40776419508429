import * as request from 'request-promise-native'
import config from '../config'
import { getCommonActions } from './commonActions'

export const REQUEST_TENANT_DASHBOARD_VALUES = 'REQUEST_TENANT_DASHBOARD_VALUES'
export const RECEIVE_TENANT_DASHBOARD_VALUES = 'RECEIVE_TENANT_DASHBOARD_VALUES'
export const ERROR_TENANT_DASHBOARD_VALUES = 'ERROR_TENANT_DASHBOARD_VALUES'
export const CLEAR_TENANT_DASHBOARD_VALUES = 'CLEAR_TENANT_DASHBOARD_VALUES'

export const tenantDashboard = () =>
  getCommonActions('TENANT_DASHBOARD', {
    mapState: state => state.tenantDashboard,
    endpoint: 'bill/tenant/dashboard',
  })

const requestTenantDashboardValues = () => {
  return {
    type: REQUEST_TENANT_DASHBOARD_VALUES,
  }
}

const receiveTenantDashboardValues = values => {
  return {
    type: RECEIVE_TENANT_DASHBOARD_VALUES,
    values,
  }
}

const errorTenantDashboardValues = err => {
  return {
    type: ERROR_TENANT_DASHBOARD_VALUES,
    err,
  }
}

export const clearTenantDashboardValues = () => {
  return {
    type: CLEAR_TENANT_DASHBOARD_VALUES,
  }
}

export const getTenantDashboardValues = () => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestTenantDashboardValues())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/bill/tenant/dashboard`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorTenantDashboardValues())

    const { err, res } = req

    if (err) {
      dispatch(errorTenantDashboardValues(err.toString()))
    } else {
      dispatch(receiveTenantDashboardValues(res))
    }
  }
}
