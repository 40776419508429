import * as request from 'request-promise-native'
import validator from 'validator'
import { requestUser, receiveUser } from './userActions'
import config from '../config'

export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const LOGOUT = 'LOGOUT'

export const requestLogin = () => {
  return {
    type: REQUEST_LOGIN,
  }
}

export const receiveLogin = loginToken => {
  return {
    type: RECEIVE_LOGIN,
    loginToken,
  }
}

const errorLogin = err => {
  return {
    type: ERROR_LOGIN,
    err,
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const login = (email, password) => {
  return async dispatch => {
    dispatch(requestLogin())
    dispatch(requestUser())

    if (!email || !email.length) return dispatch(errorLogin('Invalid email or password!'))
    if (!validator.isEmail(email)) return dispatch(errorLogin('Invalid email or password!'))

    if (!password || !password.length) return dispatch(errorLogin('Invalid email or password!'))
    if (password.length < 8) return dispatch(errorLogin('Invalid email or password!'))

    const body = {
      email,
      password,
    }

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/user/login`,
      body,
      json: true,
    }

    const req = await request(options)
    const { err, res } = req

    if (err) {
      dispatch(errorLogin(err.toString()))
    } else {
      dispatch(receiveLogin(res.loginToken))
      dispatch(receiveUser(res))
    }
  }
}
