import { CHARGES_SET_TAB } from '../actions/chargesActions'
import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  tab: 0,
  selectedOption: {
    value: 0,
    label: 'Active',
  },
}

export default (state = initialState, action) => {
  const { type, tab } = action

  switch (type) {
  case CHARGES_SET_TAB:
    return Object.assign({}, state, {
      tab,
    })
  default:
    return commonReducer('CHARGES', state, action)
  }
}
