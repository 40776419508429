import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import { REQUEST_UNIT_AVAILABILITY, RECEIVE_UNIT_AVAILABILITY, ERROR_UNIT_AVAILABILITY } from '../actions/unitsActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  availableDate: null,
}

export default (state = initialState, action) => {
  const { type, err, date } = action

  switch (type) {
  case REQUEST_UNIT_AVAILABILITY:
    return Object.assign({}, state, { isFetching: true, errFetching: null, availableDate: null })
  case RECEIVE_UNIT_AVAILABILITY:
    return Object.assign({}, state, { isFetching: false, errFetching: null, availableDate: date })
  case ERROR_UNIT_AVAILABILITY:
    return Object.assign({}, state, { isFetching: false, errFetching: err, availableDate: null })
  default:
    return commonReducer('UNITS', state, action)
  }
}
