import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import { REQUEST_UPLOAD_HVAC_RUNTIME, RECEIVE_UPLOAD_HVAC_RUNTIME, ERROR_UPLOAD_HVAC_RUNTIME } from '../actions/hvacRuntimeActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
}

export default (state = initialState, action) => {
  const { type, err } = action

  switch (type) {
  case REQUEST_UPLOAD_HVAC_RUNTIME:
    return Object.assign({}, state, { isCreating: true, errCreating: null })
  case RECEIVE_UPLOAD_HVAC_RUNTIME:
    return Object.assign({}, state, { isCreating: false, errCreating: null })
  case ERROR_UPLOAD_HVAC_RUNTIME:
    return Object.assign({}, state, { isCreating: false, errCreating: err })
  default:
    return commonReducer('HVAC_RUNTIME', state, action)
  }
}
