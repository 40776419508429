import * as request from 'request-promise-native'
import config from '../config'
import { getCommonActions } from './commonActions'
import { batchJobs } from './batchJobActions'

export const BILLS_SET_TAB = 'BILLS_SET_TAB'
export const REQUEST_GENERATE_BILLS = 'REQUEST_GENERATE_BILLS'
export const RECEIVE_GENERATE_BILLS = 'RECEIVE_GENERATE_BILLS'
export const ERROR_GENERATE_BILLS = 'ERROR_GENERATE_BILLS'
export const BILLS_CLEAR_MESSAGES = 'BILLS_CLEAR_MESSAGES'
export const REQUEST_PRINT_BILLS = 'REQUEST_PRINT_BILLS'
export const RECEIVE_PRINT_BILLS = 'RECEIVE_PRINT_BILLS'
export const ERROR_PRINT_BILLS = 'ERROR_PRINT_BILLS'
export const REQUEST_SEND_BILLS = 'REQUEST_SEND_BILLS'
export const RECEIVE_SEND_BILLS = 'RECEIVE_SEND_BILLS'
export const ERROR_SEND_BILLS = 'ERROR_SEND_BILLS'
export const REQUEST_UNDO_SEND_BILLS = 'REQUEST_UNDO_SEND_BILLS'
export const RECEIVE_UNDO_SEND_BILLS = 'RECEIVE_UNDO_SEND_BILLS'
export const ERROR_UNDO_SEND_BILLS = 'ERROR_UNDO_SEND_BILLS'
export const REQUEST_CURRENT_ACCOUNT_BILL = 'REQUEST_CURRENT_ACCOUNT_BILL'
export const RECEIVE_CURRENT_ACCOUNT_BILL = 'RECEIVE_CURRENT_ACCOUNT_BILL'
export const ERROR_CURRENT_ACCOUNT_BILL = 'ERROR_CURRENT_ACCOUNT_BILL'
export const CLEAR_CURRENT_ACCOUNT_BILL = 'CLEAR_CURRENT_ACCOUNT_BILL'
export const SET_PAYMENT_STEP = 'SET_PAYMENT_STEP'
export const REQUEST_TENANT_SUBMIT_PAYMENT = 'REQUEST_TENANT_SUBMIT_PAYMENT'
export const RECEIVE_TENANT_SUBMIT_PAYMENT = 'RECEIVE_TENANT_SUBMIT_PAYMENT'
export const ERROR_TENANT_SUBMIT_PAYMENT = 'ERROR_TENANT_SUBMIT_PAYMENT'
export const CLEAR_TENANT_SUBMIT_PAYMENT = 'CLEAR_TENANT_SUBMIT_PAYMENT'
export const SET_RESOLVE_BILL_STEP = 'SET_RESOLVE_BILL_STEP'
export const REQUEST_RESOLVE_BILL = 'REQUEST_RESOLVE_BILL'
export const RECEIVE_RESOLVE_BILL = 'RECEIVE_RESOLVE_BILL'
export const ERROR_RESOLVE_BILL = 'ERROR_RESOLVE_BILL'
export const REQUEST_DOWNLOAD_BILL_LINE_DETAIL_REPORT = 'REQUEST_DOWNLOAD_BILL_LINE_DETAIL_REPORT'
export const RECEIVE_DOWNLOAD_BILL_LINE_DETAIL_REPORT = 'RECEIVE_DOWNLOAD_BILL_LINE_DETAIL_REPORT'
export const ERROR_DOWNLOAD_BILL_LINE_DETAIL_REPORT = 'ERROR_DOWNLOAD_BILL_LINE_DETAIL_REPORT'
export const REQUEST_BATCH_DELETE_BILLS = 'REQUEST_BATCH_DELETE_BILLS'
export const RECEIVE_BATCH_DELETE_BILLS = 'RECEIVE_BATCH_DELETE_BILLS'
export const ERROR_BATCH_DELETE_BILLS = 'ERROR_BATCH_DELETE_BILLS'
export const REQUEST_BATCH_PRINT_BILLS = 'REQUEST_BATCH_PRINT_BILLS'
export const RECEIVE_BATCH_PRINT_BILLS = 'RECEIVE_BATCH_PRINT_BILLS'
export const ERROR_BATCH_PRINT_BILLS = 'ERROR_BATCH_PRINT_BILLS'

export const bills = () =>
  getCommonActions('BILLS', {
    mapState: state => state.bills,
    endpoint: 'bill',
  })

export const setTab = tab => {
  return {
    type: BILLS_SET_TAB,
    tab,
  }
}

export const clearMessages = () => {
  return {
    type: BILLS_CLEAR_MESSAGES,
  }
}

const requestGenerateBills = () => {
  return {
    type: REQUEST_GENERATE_BILLS,
  }
}

const receiveGenerateBills = message => {
  return {
    type: RECEIVE_GENERATE_BILLS,
    message,
  }
}

const errorGenerateBills = err => {
  return {
    type: ERROR_GENERATE_BILLS,
    err,
  }
}

const requestPrintBills = () => {
  return {
    type: REQUEST_PRINT_BILLS,
  }
}

const receivePrintBills = () => {
  return {
    type: RECEIVE_PRINT_BILLS,
  }
}

const errorPrintBills = err => {
  return {
    type: ERROR_PRINT_BILLS,
    err,
  }
}

const requestSendBills = () => {
  return {
    type: REQUEST_SEND_BILLS,
  }
}

const receiveSendBills = () => {
  return {
    type: RECEIVE_SEND_BILLS,
  }
}

const errorSendBills = err => {
  return {
    type: ERROR_SEND_BILLS,
    err,
  }
}

const requestUndoSendBills = () => {
  return {
    type: REQUEST_UNDO_SEND_BILLS,
  }
}

const receiveUndoSendBills = () => {
  return {
    type: RECEIVE_UNDO_SEND_BILLS,
  }
}

const errorUndoSendBills = err => {
  return {
    type: ERROR_UNDO_SEND_BILLS,
    err,
  }
}

const requestCurrentAccountBill = () => {
  return {
    type: REQUEST_CURRENT_ACCOUNT_BILL,
  }
}

const receiveCurrentAccountBill = bill => {
  return {
    type: RECEIVE_CURRENT_ACCOUNT_BILL,
    bill,
  }
}

const errorCurrentAccountBill = err => {
  return {
    type: ERROR_CURRENT_ACCOUNT_BILL,
    err,
  }
}

export const clearCurrentAccountBill = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNT_BILL,
  }
}

export const setPaymentStep = step => {
  return {
    type: SET_PAYMENT_STEP,
    step,
  }
}

const requestTenantSubmitPayment = () => {
  return {
    type: REQUEST_TENANT_SUBMIT_PAYMENT,
  }
}

const receiveTenantSubmitPayment = payment => {
  return {
    type: RECEIVE_TENANT_SUBMIT_PAYMENT,
    payment,
  }
}

export const errorTenantSubmitPayment = err => {
  return {
    type: ERROR_TENANT_SUBMIT_PAYMENT,
    err,
  }
}

export const clearTenantSubmitPayment = () => {
  return {
    type: CLEAR_TENANT_SUBMIT_PAYMENT,
  }
}

export const setResolveBillStep = step => {
  return {
    type: SET_RESOLVE_BILL_STEP,
    step,
  }
}

const requestResolveBill = () => {
  return {
    type: REQUEST_RESOLVE_BILL,
  }
}

const receiveResolveBill = bill => {
  return {
    type: RECEIVE_RESOLVE_BILL,
    bill,
  }
}

const errorResolveBill = err => {
  return {
    type: ERROR_RESOLVE_BILL,
    err,
  }
}

const requestDownloadBillLineDetailReport = () => {
  return {
    type: REQUEST_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
  }
}

const receiveDownloadBillLineDetailReport = () => {
  return {
    type: RECEIVE_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
  }
}

const errorDownloadBillLineDetailReport = err => {
  return {
    type: ERROR_DOWNLOAD_BILL_LINE_DETAIL_REPORT,
    err,
  }
}

const requestBatchDeleteBills = () => {
  return {
    type: REQUEST_BATCH_DELETE_BILLS,
  }
}

const receiveBatchDeleteBills = () => {
  return {
    type: RECEIVE_BATCH_DELETE_BILLS,
  }
}

const errorBatchDeleteBills = err => {
  return {
    type: ERROR_BATCH_DELETE_BILLS,
    err,
  }
}

const requestBatchPrintBills = () => {
  return {
    type: REQUEST_BATCH_PRINT_BILLS,
  }
}

const receiveBatchPrintBills = () => {
  return {
    type: RECEIVE_BATCH_PRINT_BILLS,
  }
}

const errorBatchPrintBills = err => {
  return {
    type: ERROR_BATCH_PRINT_BILLS,
    err,
  }
}

export const generateBills = (month, buildings, tenantEstimatedBillings) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestGenerateBills())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/billing/generate`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        month: month.getMonth(),
        year: month.getFullYear(),
        buildings: buildings,
        tenantEstimatedBillings,
      },
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorGenerateBills('There was a problem generating the bills!'))

    const { err, messages } = req

    if (err) return dispatch(errorGenerateBills(err.toString()))

    dispatch(receiveGenerateBills(messages))
    dispatch(bills().refresh({ typeId: getState().bills.tab + 1 }))
    dispatch(batchJobs().refresh())
  }
}

export const sendBills = selected => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestSendBills())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/bill/send`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        ids: selected,
      },
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorSendBills('There was a problem sending the bills!'))

    if (req.err) {
      return dispatch(errorSendBills(req.err.toString()))
    }

    dispatch(receiveSendBills())
    dispatch(bills().refresh({ typeId: getState().bills.tab + 1}))
  }
}

export const undoSendBills = selected => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestUndoSendBills())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/bill/undo-send`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        ids: selected,
      },
      json: true,
    }

    const req = await request(options)
    if (!req) return dispatch(errorUndoSendBills('There was a problem undoing the sent bills!'))

    if (req.err) {
      return dispatch(errorUndoSendBills(req.err.toString()))
    }

    dispatch(receiveUndoSendBills())
    dispatch(bills().refresh({ typeId: getState().bills.tab + 1}))
  }
}

export const printBills = (selected, forDownload = false, isUtilityStatement = false) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestPrintBills())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/billing/view`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: {
        ids: selected,
        isUtilityStatement,
      },
      resolveWithFullResponse: true,
      json: true,
    }

    const { headers, body } = await request(options)
    if (!body) return dispatch(errorPrintBills('There was a problem printing the bills!'))

    if ('err' in body) {
      console.log(body.err)
      return dispatch(errorPrintBills(body.err))
    }

    const blob = new Blob([body], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    var link = document.createElement('a')
    if (forDownload) {
      document.body.appendChild(link)
      link.style = 'display: none'
      link.href = url
      link.download = headers['content-type']
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 100)
    } else {
      link.href = url
      link.target = '_blank'
      link.click()
    }
    dispatch(receivePrintBills())
  }
}

export const batchPrintBills = (data) => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth
    dispatch(requestBatchPrintBills())

    const options = {
      method: 'PUT',
      uri: `${config.baseUrl}/billing/view/batch-print-bills`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: data,
      resolveWithFullResponse: true,
      json: true,
    }

    const { headers, body } = await request(options)
    if (!body) return dispatch(errorBatchPrintBills('There was a problem printing the bills!'))

    if ('err' in body) {
      return dispatch(errorBatchPrintBills(body.err))
    }

    const blob = new Blob([body], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    var link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.click()

    dispatch(receiveBatchPrintBills())
  }
}

export const getCurrentAccountBill = tenantId => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestCurrentAccountBill())

    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/bill/current/${tenantId}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorCurrentAccountBill())

    const { err, res: bill } = req

    if (err) {
      dispatch(errorCurrentAccountBill(err.toString()))
    } else {
      dispatch(receiveCurrentAccountBill(bill))
    }
  }
}

export const tenantSubmitPayment = payment => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestTenantSubmitPayment())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/stripe/tenant/submit-payment`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: payment,
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorTenantSubmitPayment('Something went wrong submitting your payment!'))

    const { err, res: _payment } = req

    if (err) {
      dispatch(errorTenantSubmitPayment(err.toString()))
    } else {
      dispatch(receiveTenantSubmitPayment(_payment))
    }
  }
}

export const resolveBill = data => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestResolveBill())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/bill/resolve`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: data,
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorResolveBill())

    const { err, res: bill } = req

    if (err) {
      dispatch(errorResolveBill(err.toString()))
    } else {
      dispatch(receiveResolveBill(bill))
    }
  }
}

export const downloadBillLineDetailReport = fields => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestDownloadBillLineDetailReport())

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/bill/export-bill-lines`,
      encoding: null,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      body: fields,
      resolveWithFullResponse: true,
      json: true,
    }

    const { body } = await request(options)

    if (!body) return dispatch(errorDownloadBillLineDetailReport('There was a problem downloading the bill line detail report!'))

    if ('err' in body) {
      return dispatch(errorDownloadBillLineDetailReport(body.err))
    }

    if (body.separateUtilityTypes) {
      for (let key in body) {
        if (typeof body[key] !== 'string') continue
        downloadCsv(body[key], fields, key)
      }
    } else {
      downloadCsv(body, fields)
    }

    dispatch(receiveDownloadBillLineDetailReport())
  }
}

const downloadCsv = (data, fields, utilityType) => {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)

  const start = new Date(fields.startDate)
  const end = new Date(fields.endDate)

  var link = document.createElement('a')

  document.body.appendChild(link)

  link.style = 'display: none'
  link.href = url
  link.download = `${fields.buildingNumber} ${(1 + start.getMonth()).toString().padStart(2, '0') + '-' + start.getDate().toString()
    .padStart(2, '0') + '-' + start.getFullYear()} - ${(1 + end.getMonth()).toString().padStart(2, '0') + '-' + end.getDate().toString()
    .padStart(2, '0') + '-' + end.getFullYear()}${utilityType ? ` ${utilityType}` : ''} Bill Line Detail.csv`
  link.click()

  setTimeout(() => {
    window.URL.revokeObjectURL(url)
  }, 100)
}

export const deleteBills = ids => {
  return async (dispatch, getState) => {
    const { loginToken } = getState().auth

    dispatch(requestBatchDeleteBills())

    const options = {
      method: 'DELETE',
      uri: `${config.baseUrl}/bill?ids=${JSON.stringify(ids)}`,
      headers: {
        auth: JSON.stringify({ token: loginToken }),
      },
      json: true,
    }

    const req = await request(options)

    if (!req) return dispatch(errorBatchDeleteBills())

    const { err, res: bill } = req

    if (err) {
      dispatch(errorBatchDeleteBills(err.toString()))
    } else {
      dispatch(receiveBatchDeleteBills(bill))
    }
  }
}
