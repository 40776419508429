import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import {
  SET_RESET_PASSWORD_STEP,
  REQUEST_RESET_PASSWORD,
  RECEIVE_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  REQUEST_FORGOT_PASSWORD,
  RECEIVE_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  ERROR_ACTIVATE_ACCOUNT,
  REQUEST_ACTIVATE_ACCOUNT,
  RECEIVE_ACTIVATE_ACCOUNT,
  SET_ACTIVATE_ACCOUNT_STEP,
  ERROR_ADD_TENANT_ACCOUNT,
  REQUEST_ADD_TENANT_ACCOUNT,
  RECEIVE_ADD_TENANT_ACCOUNT,
  SET_ADD_TENANT_ACCOUNT_STEP,
  REQUEST_TENANT_ACCOUNTS,
  RECEIVE_TENANT_ACCOUNTS,
  ERROR_TENANT_ACCOUNTS,
  ERROR_SET_ACTIVE_TENANT_ACCOUNT,
  REQUEST_SET_ACTIVE_TENANT_ACCOUNT,
  RECEIVE_SET_ACTIVE_TENANT_ACCOUNT,
  USER_ACCOUNTS_SET_TAB,
} from '../actions/userAccountsActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  resetPasswordStep: 0,
  activateAccountStep: 0,
  addTenantAccountStep: 0,
  redirect: false,
  tab: 0,
  activatedAccount: null,
  tenants: [],
  errFetchingTenants: null,
}

export default (state = initialState, action) => {
  const { type, id, step, err, tab, user, tenants } = action

  switch (type) {
  case SET_RESET_PASSWORD_STEP:
    return Object.assign({}, state, { opened: id ? { id } : {}, resetPasswordStep: step })
  case REQUEST_RESET_PASSWORD:
    return Object.assign({}, state, { isFetching: true, errFetching: null, redirect: false })
  case RECEIVE_RESET_PASSWORD:
    return Object.assign({}, state, {
      isFetching: false,
      errFetching: null,
      opened: user,
      redirect: true,
      resetPasswordStep: 0
    })
  case ERROR_RESET_PASSWORD:
    return Object.assign({}, state, { isFetching: false, errFetching: err, redirect: false })
  case REQUEST_FORGOT_PASSWORD:
    return Object.assign({}, state, { isFetching: true, errFetching: null, redirect: false })
  case RECEIVE_FORGOT_PASSWORD:
    return Object.assign({}, state, { isFetching: false, errFetching: null, redirect: true })
  case ERROR_FORGOT_PASSWORD:
    return Object.assign({}, state, { isFetching: false, errFetching: err, redirect: false })
  case SET_ACTIVATE_ACCOUNT_STEP:
    return Object.assign({}, state, { opened: id ? { id } : {}, activateAccountStep: step })
  case ERROR_ACTIVATE_ACCOUNT:
    return Object.assign({}, state, { isFetching: false, errFetching: err, redirect: false })
  case REQUEST_ACTIVATE_ACCOUNT:
    return Object.assign({}, state, { isFetching: true, errFetching: err, redirect: false })
  case RECEIVE_ACTIVATE_ACCOUNT:
    return Object.assign({}, state, {
      isFetching: false,
      errFetching: null,
      opened: {},
      activateAccountStep: 0,
      redirect: true,
      activatedAccount: user,
    })
  case USER_ACCOUNTS_SET_TAB:
    return Object.assign({}, state, { tab })
  case SET_ADD_TENANT_ACCOUNT_STEP:
    return Object.assign({}, state, { opened: id ? { id } : {}, addTenantAccountStep: step, errFetching: null })
  case ERROR_ADD_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: false, errFetching: err, redirect: false })
  case REQUEST_ADD_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: true, errFetching: err, redirect: false })
  case RECEIVE_ADD_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: false, errFetching: null, addTenantAccountStep: 0 })
  case ERROR_TENANT_ACCOUNTS:
    return Object.assign({}, state, { isFetching: false, errFetchingTenants: err, tenants: [] })
  case REQUEST_TENANT_ACCOUNTS:
    return Object.assign({}, state, { isFetching: true, errFetchingTenants: err, tenants: [] })
  case RECEIVE_TENANT_ACCOUNTS:
    return Object.assign({}, state, { isFetching: false, errFetchingTenants: null, tenants })
  case ERROR_SET_ACTIVE_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: false, errSetActiveTenant: err })
  case REQUEST_SET_ACTIVE_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: true, errSetActiveTenant: null })
  case RECEIVE_SET_ACTIVE_TENANT_ACCOUNT:
    return Object.assign({}, state, { isFetching: false, errSetActiveTenant: null })
  default:
    return commonReducer('USER_ACCOUNTS', state, action)
  }
}
