import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
  default:
    return commonReducer('CONTACT', state, action)
  }
}

/*import {
  ERROR_CREATE_CONTACT,
  RECEIVE_CREATE_CONTACT,
  REQUEST_CREATE_CONTACT,
  CLEAR_CONTACT
} from '../actions/contactActions'

const initialState = {
  id: null,
  isCreating: false,
  err: null
}

export default (state = initialState, action) => {
  const { contact, err, type } = action

  switch(type) {
    case REQUEST_CREATE_CONTACT:
      return Object.assign({}, state, { isCreating: true, err: null })

    case RECEIVE_CREATE_CONTACT:
      return Object.assign({}, state, { id: contact.id, isCreating: false, err: null })

    case ERROR_CREATE_CONTACT:
      return Object.assign({}, state, { isCreating: false, err })

    case CLEAR_CONTACT:
      return initialState

    default:
      return state
  }
}*/
