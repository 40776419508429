export const options = [
  {
    value: 0,
    type: 'LIKE',
    left: '%',
    right: '%',
    label: 'Contains',
  },
  {
    value: 1,
    type: '=',
    label: 'Equals',
  },
  {
    value: 2,
    type: '!=',
    label: 'Not equals',
  },
  {
    value: 3,
    type: 'LIKE',
    right: '%',
    label: 'Starts with',
  },
  {
    value: 4,
    type: 'LIKE',
    left: '%',
    label: 'Ends with',
  },
]

const getValue = (index, value) => {
  const option = options[index]

  let result = ''
  if (option.left) {
    result += option.left
  }
  result += value
  if (option.right) {
    result += option.right
  }

  return result
}

export const forAPI = filters => {
  if (!filters) return null
  let result = {}
  Object.keys(filters).forEach(key => {
    let filter = filters[key]
    if (typeof filter === 'object') {
      result[key] = {
        type: options[filter.option].type,
        value: getValue(filter.option, filter.value),
      }
    } else {
      result[key] = filter
    }
  })
  return result
}
