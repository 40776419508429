import { commonReducer } from './commonReducer'
import { grid, pagination, viewEdit } from './commonStates'
import {
  REQUEST_DOWNLOAD_PAYMENTS_REPORT,
  RECEIVE_DOWNLOAD_PAYMENTS_REPORT,
  ERROR_DOWNLOAD_PAYMENTS_REPORT
} from '../actions/paymentsActions'

const initialState = {
  ...grid(),
  ...pagination(),
  ...viewEdit(),
  isDownloadingPaymentsReport: false,
  errDownloadingPaymentsReport: null,
}

export default (state = initialState, action) => {
  const { type, err } = action

  switch (type) {
  case REQUEST_DOWNLOAD_PAYMENTS_REPORT:
    return Object.assign({}, state, {
      isDownloadingPaymentsReport: true,
      errDownloadingPaymentsReport: null,
    })
  case RECEIVE_DOWNLOAD_PAYMENTS_REPORT:
    return Object.assign({}, state, {
      isDownloadingPaymentsReport: false,
      errDownloadingPaymentsReport: null,
    })
  case ERROR_DOWNLOAD_PAYMENTS_REPORT:
    return Object.assign({}, state, {
      isDownloadingPaymentsReport: false,
      errDownloadingPaymentsReport: err,
    })
  default:
    return commonReducer('PAYMENTS', state, action)
  }
}
