import * as request from 'request-promise-native'
import config from '../config'

export const numRows = async (endpoint, token, fields = null, search = null) => {
  let fieldsUri = ''
  if (fields) {
    fieldsUri = `?fields=${encodeURIComponent(JSON.stringify(fields))}`
  }

  let searchUri = ''
  if (search) {
    let start = '&'
    if (fieldsUri === '') {
      start = '?'
    }
    searchUri = start + `search=${encodeURIComponent(JSON.stringify(search))}`
  }

  const options = {
    method: 'GET',
    uri: `${config.baseUrl}/${endpoint}/num-rows${fieldsUri + searchUri}`,
    headers: {
      auth: JSON.stringify({ token }),
    },
    json: true,
  }

  return await request(options)
}
