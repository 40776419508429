import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import * as request from 'request-promise-native'
import config from './config'
import Loadable from 'react-loadable'
import { isMobile } from 'mobile-device-detect'
import './App.scss'
import './App.css'

Moment.locale('en')
momentLocalizer()

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className={'sk-spinner sk-spinner-pulse'} />
  </div>
)

const Admin = Loadable({
  loader: () => import('./containers/Admin'),
  loading,
})

const Mgmt = Loadable({
  loader: () => import('./containers/Mgmt'),
  loading,
})

const Tenant = Loadable({
  loader: () => import('./containers/Tenant'),
  loading,
})

const Login = Loadable({
  loader: () => import('./containers/Login'),
  loading,
})

const Register = Loadable({
  loader: () => import('./containers/Register'),
  loading,
})

const ResetPassword = Loadable({
  loader: () => import('./containers/ResetPassword'),
  loading,
})

const ForgotPassword = Loadable({
  loader: () => import('./containers/ForgotPassword'),
  loading,
})

const ActivateAccount = Loadable({
  loader: () => import('./containers/ActivateAccount'),
  loading,
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: true }

    config.baseUrl = request(`${window.location.origin}/config.json`, { json: true }, (err, res) => {
      if (!err) {
        config.baseUrl = res.body.baseUrl
        config.publicStripeKey = res.body.publicStripeKey
        config.testToken = res.body.testToken
        this.setState({ loading: false })
      }
    })
  }

  componentDidMount() {
    if (isMobile) {
      let that = this
      setTimeout(function() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        window.addEventListener('resize', that.onResize)
      })
    }
  }

  onResize = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  render() {
    if (!this.state.loading) {
      return (
        <Router>
          <Switch>
            <Route exact path={'/'} name={'Login'} component={Login} />
            <Route exact path={'/register'} name={'Register'} component={Register} />
            <Route path={'/reset-password'} name={'Reset Password'} component={ResetPassword} />
            <Route path={'/forgot-password'} name={'Forgot Password'} component={ForgotPassword} />
            <Route path={'/activate-account'} name={'Activate Account'} component={ActivateAccount} />
            <Route path={'/admin'} name={'Admin Home'} component={Admin} />
            <Route path={'/mgmt'} name={'Management Home'} component={Mgmt} />
            <Route path={'/tenant'} name={'Tenant Home'} component={Tenant} />
          </Switch>
        </Router>
      )
    } else {
      return loading()
    }
  }
}

export default App
